
import sportsIcon from "../assets/imagesNew/icon-sport.webp";
import casinoIcon from "../assets/imagesNew/icon-casino.webp";
import slotIcon from "../assets/imagesNew/icon-slot.webp";
import TableIcon from "../assets/imagesNew/icon-table.webp";
import fishicon from "../assets/imagesNew/icon-fish.webp";
import arcadeIcon from "../assets/imagesNew/icon-crash.webp";
import lotteryIcon from "../assets/imagesNew/icon-lottery.webp";


import withdrawIcon from "../assets/imagesNew/withdraw.png";
import DepositIcon from "../assets/imagesNew/money.png";
import liveCasino1 from "../assets/imagesNew/live_casino1.webp";
import liveCasino2 from "../assets/imagesNew/live_casino2.webp";
import bannerSlider1 from "../assets/imagesNew/banner-slider1.jpg";
import bannerSlider2 from "../assets/imagesNew/banner-slider2.jpg";

import feature1 from "../assets/imagesNew/feature1.webp";
import feature2 from "../assets/imagesNew/feature2.webp";
import feature3 from "../assets/imagesNew/feature3.webp";
import feature4 from "../assets/imagesNew/feature4.webp";
import feature5 from "../assets/imagesNew/feature5.webp";
import feature6 from "../assets/imagesNew/feature6.webp";
import feature7 from "../assets/imagesNew/feature7.webp";


import Jili from "../assets/imagesNew/jilii.png";
import esport from "../assets/images/esport.webp";

import f1 from "../assets/images/f1.webp";
import f2 from "../assets/images/f2.webp";
import f3 from "../assets/images/f3.webp";
import f4 from "../assets/images/f4.webp";
import f5 from "../assets/images/f5.webp";
import f6 from "../assets/images/f6.webp";


import js1 from "../assets/images/js1.webp";
import js2 from "../assets/images/js2.webp";
import js3 from "../assets/images/js3.webp";
import js4 from "../assets/images/js4.webp";
import js5 from "../assets/images/js5.webp";
import js6 from "../assets/images/js6.webp";


import hotIcon from "../assets/images/icon-hot.gif";


import loginBanner1 from "../assets/images/login-banner1.webp";
import loginBanner2 from "../assets/images/login-banner2.webp";
import loginBanner3 from "../assets/images/login-banner3.webp";

import iconExchange from "../assets/images/icon-exchange.webp";
import iconSportbook from "../assets/images/icon-sportbook.svg";
import iconHorsebook from "../assets/images/icon-horsebook.svg";
import velkieLogo from "../assets/images/logo-velki.svg";
import awcme1sport from "../assets/images/icon-awcme1sport.svg";
import dragont from "../assets/images/dragont.svg";



import fc from "../assets/images/fc.png";
import jdb from "../assets/images/jdb.png";
import km from "../assets/images/km.png";
import ludo from "../assets/images/ludo.png";
import pg from "../assets/images/pg.png";
import pt from "../assets/images/pt.png";


import evo from "../assets/images/evo.png";

import pp from "../assets/images/pp.png";
import yl from "../assets/images/yl.png";
import yesbingo from "../assets/images/provider-awcmyesbingo.png";
import number from "../assets/images/provider-number.png";
import dragonsoft from "../assets/images/DRAGONSOFT.webp";
import biggaming from "../assets/images/BIGGAMING.webp";
import sabavrtual from "../assets/images/SABAVIRTUAL.webp";
import ezugi from "../assets/images/EZUGI.webp";
import superspade from "../assets/images/SUPERSPADE.webp";
import onetouch from "../assets/images/ONETOUCH.webp";
import spribe from "../assets/images/SPRIBE.webp";
import bombaylive from "../assets/images/BOMBAYLIVE.webp";
import royalgaming from "../assets/images/ROYALGAMING.webp";
import sabasport from "../assets/images/saba-sport.png";
import betgames from "../assets/images/BETGAMES.webp";
import fastspin from "../assets/images/FASTSPIN.webp";
import sv388 from "../assets/images/SV388.webp";
import rt from "../assets/images/REDTIGER.webp";
import sexy from "../assets/images/SEXYBCRT.webp";
import sg from "../assets/images/SPADEGAMING.webp";


import evoNew from "../assets/images/evolution.webp";
import ezugiNew from "../assets/images/evolution-new.webp";
import jiliNew from "../assets/images/jililobby.webp";
import pragmaticNew from "../assets/images/pragmatic-play.webp";
import aeSexy from "../assets/images/aeSexy.webp";
import horseRacing from "../assets/images/horse-racing.gif";
import ptNew from "../assets/images/ptNew.jpg";
import bgNew from "../assets/images/BigG.webp";

import fav1 from "../assets/images/fav1.jpg";
import fav2 from "../assets/images/fav2.jpg";
import fav3 from "../assets/images/fav3.jpg";
import fav4 from "../assets/images/fav4.jpg";

import pakistanFlag from "../assets/images/flag-symbolism-Pakistan-design-Islamic.png";
import camodiaFlag from "../assets/images/Flag-Cambodia.png";
import philippinesFlag from "../assets/images/Flag_of_philippines.svg.jpg";
import brazilFlag from "../assets/images/Flag_of_Brazil.svg.png";
import indiaFlag from "../assets/images/Flag_of_India.svg.png";
import bangladeshFlag from "../assets/images/Flag_of_Bangladesh.svg.png";
// hotgame Image
import pmegasicbo from "../assets/images/hot-casino/pmegasicbo.webp";
import pmegawheel from "../assets/images/hot-casino/pmegawheel.webp";
import pmegaroll from "../assets/images/hot-casino/pmegaroll.webp";
import pbaccarat from "../assets/images/hot-casino/pbaccarat.webp";
import pdt from "../assets/images/hot-casino/pdt.webp";
import ppsicbo from "../assets/images/hot-casino/ppsicbo.webp";
import pthilo from "../assets/images/hot-casino/pthilo.webp";
import ptspinwin from "../assets/images/hot-casino/ptspinwin.webp";
import playtechteenpatti from "../assets/images/hot-casino/playtech-teen-patti.webp";
import bgroll from "../assets/images/hot-casino/bgroll.webp";
import bgsicbo from "../assets/images/hot-casino/bgsicbo.webp";
import bgspeedsicbo from "../assets/images/hot-casino/bgspeedsicbo.webp";
import pcrazytime from "../assets/images/hot-casino/pcrazytime.webp";
import pmonopoly from "../assets/images/hot-casino/pmonopoly.webp";
import pcoinflip from "../assets/images/hot-casino/pcoinflip.webp";
import evoteenpatti from "../assets/images/hot-casino/evoteenpatti.webp";
import evosupersicbo from "../assets/images/hot-casino/evosupersicbo.webp";
import evospeedroullete from "../assets/images/hot-casino/evospeedroullete.webp";
import evolightdice from "../assets/images/hot-casino/evolightdice.webp";
import evoligtroullete from "../assets/images/hot-casino/evoligtroullete.webp";
import ppbacarrat2 from "../assets/images/hot-casino/ppbacarrat2.webp";

import hotgame028 from "../assets/images/hot-casino/KM-TABLE-028.webp";
import hotgame035 from "../assets/images/hot-casino/JILI-TABLE-035.webp";
import hotgame030 from "../assets/images/hot-casino/JILI-TABLE-030.webp";
import hotgame060 from "../assets/images/hot-casino/KM-TABLE-060.webp";

import hotgame047 from "../assets/images/hot-casino/JILI-TABLE-047.webp";
import hotgame015 from "../assets/images/hot-casino/KM-TABLE-015.webp";
import hotgame021 from "../assets/images/hot-casino/KM-TABLE-021.webp";
import hotgame022 from "../assets/images/hot-casino/KM-TABLE-022.webp";
import pgdragon from "../assets/images/dragon-tiger.png";

const obj = {
  user_status: {
    owner: "OW",
    sub_owner: "SOW",
    super_admin: "SUA",
    admin: "AD",
    sub_admin: "SAD",
    senior_super: "SSM",
    super_agent: "SA",
    agent: "AG",
    user: "CL",
  },
  user_next_status: {
    owner: "sub_owner",
    sub_owner: "super_admin",
    super_admin: "admin",
    admin: "sub_admin",
    sub_admin: "senior_super",
    senior_super: "super_agent",
    super_agent: "agent",
    agent: "user",
  },
  market_category: {
    1: "Market",
    3: "Bookmakers",
    5: "ManualOdds",
    10: "Win Toss",
    2: "Session",
    6: "Over by Over Session Market",
    7: "Player",
    9: "Wicket",
    11: "Bowler Session",
    8: "Last Digit ODD",
    4: "LineMarket",
    14: "Premium ODDs",
  },
  betCheckObj: {
    4: "Cricket",
    2: "Tennis",
    1: "Soccer",
    3: "Casino",
  },
  matchType: {
    cricket: "Cricket",
    tennis: "Tennis",
    soccer: "Soccer",
    casino: "Casino",
  },
};
export default obj;
export const priceFormat = (value) => {
  return new Intl.NumberFormat().format(value);
};
export const LoginBanner = [
  { id: 1, banner: loginBanner1 },
  { id: 2, banner: loginBanner2 },
  { id: 3, banner: loginBanner3 },
];
export const CountryFlagBanner = [
  { id: 1, banner: pakistanFlag,title:"Pakistan" },
  { id: 2, banner: camodiaFlag ,title:"camodia"},
  { id: 3, banner: philippinesFlag ,title:"philippines"},
  { id: 4, banner: brazilFlag ,title:"brazil"},
  { id: 5, banner: indiaFlag,title:"india" },
  { id: 6, banner: bangladeshFlag ,title:"bangladesh"},
];



export const FeaturedGames = [
  {
    id: 1,
    icons: feature1,
    title: "Lightening Storm",
    titlebn: "Lightening Storm",
    gameTypeCheck: "platForm",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-369",
  },
  {
    id: 2,
    icons: feature2,
    title: "7up7down",
    titlebn: "7 আপ 7 ডাউন",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-011",
  },

  {
    icons: feature3,
    title: "Cricket King 18",
    titlebn: "ক্রিকেট কিং ১৮",
    gameType: "SLOT",
    platForm: "JILI",
    casinoType: "JILI-SLOT-078",
  },
  {
    icons: feature4,
    title: "Super Ace",
    titlebn: "সুপার এস",
    gameTypeCheck: "platForm",
    gameType: "SLOT",
    platForm: "JILI",
    casinoType: "JILI-SLOT-027",
  },
  {
    icons: feature5,
    title: "Wild Ace",
    titlebn: "বন্য টেক্কা",
    gameTypeCheck: "platForm",
    gameType: "SLOT",
    platForm: "JILI",
    casinoType: "JILI-SLOT-075",
  },
  {
    icons: feature6,
    title: "CrazySeven",
    titlebn: "ক্রেজি সেভেন",
    gameTypeCheck: "platForm",
    gameType: "SLOT",
    platForm: "JILI",
    casinoType: "JILI-SLOT-014",
  },
  {
    icons: feature7,
    title: "Cricket Sah 75",
    titlebn: "ক্রিকেট সাহ 75",
    gameTypeCheck: "platForm",
    gameType: "SLOT",
    platForm: "JILI",
    casinoType: "JILI-SLOT-082",
  },
  
];
export const BannerSliderData = [
  { id: 1, bannerImg: bannerSlider1 },
  { id: 2, bannerImg: bannerSlider2 },
];
export const SlotData = [
  {
    id: 1,
    icons: f1,
    title: "Aviator",
    titlebn: "বৈমানিক",
    gameTypeCheck: "platForm",
    platForm: "SPRIBE",
    gameType: "EGAME",
    casinoType: "SPRIBE-EGAME-001",
  },
  {
    id: 2,
    icons: f2,
    title: "Aviatrix",
    titlebn: "Aviatrix",
    gameTypeCheck: "platForm",
    platForm: "SPRIBE",
    gameType: "EGAME",
    casinoType: "SPRIBE-EGAME-002",
  },
  {
    id: 2,
    icons: f3,
    title: " Go Rush",
    titlebn: " Go Rush",
    gameTypeCheck: "platForm",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-030",
  },
  {
    id: 2,
    icons: f4,
    title: "Crash Bonus",
    titlebn: "Crash Bonus",
    gameTypeCheck: "platForm",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-047",
  },
  {
    id: 2,
    icons: f5,
    title: "Money Coming",
    titlebn: "Money Coming",
    gameTypeCheck: "platForm",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-029",
  },
  {
    id: 2,
    icons: f6,
    title: "Crazy Time",
    titlebn: "Crazy Time",
    gameTypeCheck: "platForm",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-006",
  },
];

export const bajjeSpecialData = [
  {
    id: 1,
    icons: js1,
    title: "Funky time",
    titlebn: "Funky time",
    gameTypeCheck: "platForm",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-183",
  },
  {
    id: 2,
    icons: js2,
    title: "Monopoly",
    titlebn: "Monopoly",
    gameTypeCheck: "platForm",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-007",
  },
  {
    id: 2,
    icons: js3,
    title: "Mega Wheel",
    titlebn: "Mega Wheel",
    gameTypeCheck: "platForm",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-038",
  },
  {
    id: 2,
    icons: js5,
    title: "Fortune Gem",
    titlebn: "Fortune Gem",
    gameTypeCheck: "platForm",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-043",
  },
  {
    id: 2,
    icons: js4,
    title: "Rocket Cash",
    titlebn: "Rocket Cash",
    gameTypeCheck: "platForm",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-041",
  },
  {
    id: 2,
    icons: js6,
    title: "Boxing King",
    titlebn: "Boxing King",
    gameTypeCheck: "platForm",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-031",
  },
];












export const TableEgameData = [
  {
    id: 1,
    icons: fav1,
    link:""
  },
  {
    id: 2,
    icons: fav2,
    link:""
  },
  {
    id: 3,
    icons: fav3,
    link:""
    
  }, {
    id: 3,
    icons: fav4,
    link:""
    
  },
  
];
export const DepositWithrawDataNew = [
  {
    id: 1,
    icons: DepositIcon,
    title: "Deposit",
    link: "/deposit",
    titlebn: "জমা",
  },
  {
    id: 2,
    icons: withdrawIcon,
    title: "Withdraw",
    link: "/withdraw",
    titlebn: "প্রত্যাহার করুন",
  },
];
export const DepositWithrawData = [

  {
    id: 1,
    icons: sportsIcon,
    title: "Sports",
    type: "sports",
    titlebn: "বিনিময়",
    position:'translate(5px, -7px)',
  },
  {
    id: 2,
    icons: casinoIcon,
    title: "Casino",
    titlebn: "লাইভ ক্যাসিনো",
    type: "casino",
    position:'translate(-5px, 2px)',
  },
  { id: 3, 
    icons: slotIcon,
     title: "Slot",
      titlebn: "স্লট",
       type: "slot" ,
       position:'translate(-1px, -7px)',
       
      },
  { id: 4, 
    icons:TableIcon,
    title: "Table",
    titlebn: "টেবিল",
    type: "table" ,
    position:'translate(-9px, 3px)',
  },
  {
    id: 5,
    icons: fishicon,
    title: "Fishing",
    titlebn: "মাছ ধরা",
    type: "fish",
    position:'translate(-4px, -5px)',
  },
  {
    id: 5,
    icons: arcadeIcon,
    title: "Arcade",
    type: "arcade",
    titlebn: "স্পোর্টসবুক",
    position:'translate(-5px, 2px)',
  },
  
  {
    id: 6,
    icons: lotteryIcon,
    title: "Lottery",
    titlebn: "খেলাধুলা",
    type: "lottery",
    position:'translate(-7px, 9px)',
  },
  
];

export const DepositWithrawDataArray = [
  
  {
    id: 1,
    title: "Sports",
    key: "sports",
    titlebn: "বিনিময়",
    array: [
      {
        image: iconExchange,
        title: "Exchange",
        gameTypeCheck: "sports",
        link: `${process.env.REACT_APP_SKY_URL}bkash/${localStorage.getItem(
          "token"
        )}`,
      },
      {
        image: velkieLogo,
        title: "Velki",
        gameTypeCheck: "sports",
        link: `${process.env.REACT_APP_VELKIE_URL}bkash/${localStorage.getItem(
          "token"
        )}`,
      },
      {
        image: iconSportbook,
        title: "Virtual",
        link: "",
        gameTypeCheck: "platForm",
        platForm: "SABA",
        gameType: "VIRTUAL",
        casinoType: "SABA-VIRTUAL-001",
      },
      {
        image: sabasport,
        title: "Saba Sports",
        link: "",
        gameTypeCheck: "platForm",
        platForm: "SABA",
        gameType: "",
        casinoType: "",
        islobbyTrue:true
      },
      {
        image: iconHorsebook,
        title: "Horce Racing",
        link: "",
        gameTypeCheck: "platForm",
        platForm: "HORSEBOOK",
        gameType: "LIVE",
        casinoType: "HRB-LIVE-001",
      },
      {
        image: awcme1sport,
        title: "E1SPORT",
        gameTypeCheck: "platForm",
        gameType: "ESPORTS",
        platForm: "E1SPORT",
        casinoType:"E1-ESPORTS-001"
      },
      {
        image: ludo,
        title: "LUDO",
        
        gameTypeCheck: "platForm",
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-060",
      }, {
        image: pgdragon,
        title: "DRAGON TIGER",       
        gameTypeCheck: "platForm",
        platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-042",
      }
      
    ],
  },
  {
    id: 2,
    title: "Casino",
    key: "casino",
    titlebn: "লাইভ ক্যাসিনো",
    array: [
      {
        image: Jili,
        title: "JILI",
        type: "jili",
        key: "live",
        gameTypeCheck: "platForm",
        platForm: "JILI",
      },
      {
        image: km,
        title: "KINGMAKER",
        type: "kingmaker",
        key: "live",
        gameTypeCheck: "platForm",
        gameType: "TABLE",
        platForm: "KINGMAKER",
      }, 
      {
        image: ludo,
        title: "LUDO",
        type: "ludo",
        key: "live",
        gameTypeCheck: "platForm",
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-060",
      },
      {
        image: evo,
        title: "EVOLUTION",
        type: "evo",
        key: "live",
        gameTypeCheck: "platForm",
        platForm: "EVOLUTION",
        gameType: "",
        casinoType: "",
      },
      { image: betgames,
        title: "BETGAMES",
        type: "bg",
        key: "live",
        gameTypeCheck: "platForm",
        platForm: "BG",
        gameType: "LIVE",
        casinoType: "BG-LIVE-001",
      },
        {
        image: rt,
        title: "REDTIGER",
        type: "rt",
        key: "live",
        gameTypeCheck: "platForm",
        gameType: "LIVE",
        platForm: "RT",
      },
      {
        image: sg,
        title: "SPADEGAMING",
        type: "spade",
        key: "live",
        gameTypeCheck: "platForm",
        gameType: "live",
        platForm: "SPADE",
      },
      {
        image: fc,
        title: "FC",
        type: "fc",
        key: "live",
        gameTypeCheck: "platForm",
        gameType: "LIVE",
        platForm: "FC",
      },
      {
      image: jdb,
      title: "JDB",
      type: "jdb",
      key: "live",
      gameTypeCheck: "platForm",
      gameType: "LIVE",
      platForm: "JDB",
    },
    {
      image: yl,
      title: "YL",
      type: "yl",
      key: "live",
      gameTypeCheck: "platForm",
      gameType: "LIVE",
      platForm: "YL",
    },

    {
      image: dragonsoft,
      title: "DRAGONSOFT",
      type: "dragonsoft",
      key: "live",
      gameTypeCheck: "platForm",
      gameType: "LIVE",
      platForm: "DRAGONSOFT",
    },
    {
      image: pp,
      title: "PRAGMATICPLAY",
      type: "pp",
      key: "live",
      gameTypeCheck: "platForm",
      platForm: "PP",
      gameType: "LIVE",
      casinoType: "PP-LIVE-001",
    },
    {
      image: pt,
      title: "PLAYTECH",
      type: "pt",
      key: "live",
      gameTypeCheck: "platForm",
      platForm: "PT",
    },
  
    {
      image: yesbingo,
      title: "YESBINGO",
      type: "yesbingo",
      key: "live",
      gameTypeCheck: "platForm",
      gameType: "SLOT",
      platForm: "YESBINGO",
    },
      {
        image: sexy,
        title: "SEXYBCRT",
        type: "sexy",
        key: "live",
        gameTypeCheck: "platForm",
        platForm: "SEXYBCRT",
        gameType: "LIVE",
        casinoType: "MX-LIVE-002",
      },
    // {
    //     image: fastspin,
    //     title: "FASTSPIN",
    //     type: "FastSpin",
    //     key: "live",
    //     gameTypeCheck: "platForm",
    //     gameType: "SLOT",
    //     platForm: "FASTSPIN",
    //   },
      {
        image: esport,
        title: "E1SPORT",
        type: "e1sports",
        key: "live",
        gameTypeCheck: "platForm",
        gameType: "ESPORTS",
        platForm: "E1SPORT",
        casinoType:"E1-ESPORTS-001"
      },
      {
        image: biggaming,
        title: "BIGGAMING",
        type: "biggaming",
        key: "live",
        gameTypeCheck: "platForm",
        gameType: "LIVE",
        platForm: "BG",
        casinoType:""
      },
      {
        image: pg,
        title: "PGSOFT",
        type: "pg",
        key: "slot",
        gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "PG",
      },
  
      {
        image: sv388,
        title: "SV388",
        type: "sv",
        key: "live",
        gameTypeCheck: "platForm",
        platForm: "SV388",
      },

      {
        image: sabavrtual,
        title: "SABAVIRTUAL",
        type: "SABAVIRTUAL",
        key: "live",
        gameTypeCheck: "platForm",
        platForm: "SABA",
      },
      // { image: ezugi,
      //   title: "Ezugi",
      //   type: "ezugi",
      //   key: "live",
      //   gameType: "egtGame",
      //   platForm: "Ezugi",
      //   gameTypeNew: "",
      //   casinoType: "",
      // },
      // { image: superspade,
      //   title: "SUPERSPADE",
      //   type: "SUPERSPADE",
      //   key: "live",
      //   gameType: "egtGame",
      //   platForm: "SUPERSPADE",
      //   gameTypeNew: "",
      //   casinoType: "",
      // },


  //  {
  //   image: onetouch,
  //   title: "ONETOUCH",
  //   type: "ONETOUCH",
  //   key: "live",
  //   gameTypeCheck: "platForm",
  //   platForm: "ONETOUCH",
  // },
  {
    image: spribe,
    title: "SPRIBE",
    type: "SPRIBE",
    key: "live",
    gameTypeCheck: "platForm",
    platForm: "SPRIBE",
  },
  // {
  //   image: bombaylive,
  //   title: "SPRIBE",
  //   type: "SPRIBE",
  //   key: "live",
  //   gameTypeCheck: "platForm",
  //   platForm: "SPRIBE",
  // },
  // {
  //   image: royalgaming,
  //   title: "SPRIBE",
  //   type: "SPRIBE",
  //   key: "live",
  //   gameTypeCheck: "platForm",
  //   platForm: "SPRIBE",
  // },
    ],
  },
  {
    id: 2,
    title: "Slot",
    key: "slot",
    titlebn: "স্লট",
    array: [
      {
        image: Jili,
        title: "JILI",
        type: "jili",
        key: "slot",
        gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "JILI",
      },
      {
        image: jdb,
        title: "JDB",
        type: "jdb",
        key: "slot",
        gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "JDB",
      },
      {
        image: pp,
        title: "PP",
        type: "pp",
        key: "slot",
        gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "PP",
      },

      {
        image: pt,
        title: "PT",
        type: "pt",
        key: "slot",
        gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "PT",
      },


   {
        image: rt,
        title: "RT",
        type: "rt",
        key: "slot",
        gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "FC",
      },
      {
        image: fc,
        title: "FC",
        type: "fc",
        key: "slot",
        gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "FC",
      },
      {
        image: yesbingo,
        title: "Yes Bingo",
        type: "yesbingo",
        key: "slot",
        gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "YESBINGO",
      },

      {
        image: sg,
        title: "SG",
        type: "spade",
        key: "slot",
        gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "SPADE",
      },

      

   {
    image: dragonsoft,
    title: "DRAGONSOFT",
    type: "dragonsoft",
    key: "slot",
    gameTypeCheck: "platForm",
    gameType: "LIVE",
    platForm: "DRAGONSOFT",
  },
  {
    image: onetouch,
    title: "ONETOUCH",
    type: "ONETOUCH",
    key: "live",
    gameTypeCheck: "platForm",
    platForm: "ONETOUCH",
  },

  // {
  //   image: fastspin,
  //   title: "FASTSPIN",
  //   type: "FastSpin",
  //   key: "live",
  //   gameTypeCheck: "platForm",
  //   gameType: "SLOT",
  //   platForm: "FASTSPIN",
  // },

      {
        image: pg,
        title: "PG",
        type: "pg",
        key: "slot",
        gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "PG",
      },
     
   
     
     
    ],
  },
  {
    id: 3,
    title: "Table",
    key: "table",
    titlebn: "টেবিল",
    array: [
      {
        image: km,
        title: "KM",
        type: "kingmaker",
        key: "table",
        gameTypeCheck: "platForm",
        gameType: "TABLE",
        platForm: "KINGMAKER",
      },
      
      {
        image: Jili,
        title: "JILI",
        type: "jili",
        key: "table",
        gameTypeCheck: "platForm",
        gameType: "TABLE",
        platForm: "JILI",
      },
      {
        image: jdb,
        title: "JDB",
        type: "jdb",
        key: "table",
        gameTypeCheck: "platForm",
        gameType: "TABLE",
        platForm: "JDB",
      },
      {
        image: rt,
        title: "REDTIGER",
        type: "rt",
        key: "table",
        gameTypeCheck: "platForm",
        gameType: "TABLE",
        platForm: "RT",
      },
    
      
      {
        image: ludo,
        title: "LUDO",
        type: "ludo",
        key: "table",
        gameTypeCheck: "platForm",
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-060",
      },
      // {
      //   image: spribe,
      //   title: "SPRIBE",
      //   type: "SPRIBE",
      //   key: "table",
      //   gameTypeCheck: "platForm",
      //   gameType: "TABLE",
      //   platForm: "SPRIBE",
      //   casinoType: "SPRIBE-TABLE-001",
      // },
      // {
      //   image: royalgaming,
      //   title: "SPRIBE",
      //   type: "SPRIBE",
      //   key: "TABLE",
      //   gameTypeCheck: "platForm",
      //   platForm: "SPRIBE",
      //   gameType: "TABLE",
      // },
      // {
      //   image: pg,
      //   title: "PGSOFT",
      //   type: "pg",
      //   key: "TABLE",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "PG",
      //   gameType: "TABLE",
      // },
      // {
      //   image: bombaylive,
      //   title: "SPRIBE",
      //   type: "SPRIBE",
      //   key: "TABLE",
      //   gameTypeCheck: "platForm",
      //   platForm: "SPRIBE",
      //   gameType: "TABLE",
      // },
      // {
      //     image: "",
      //     title: "",
      //     type: "",
      //     key: "",
      //     gameTypeCheck: "",
      //     gameType: "",
      //     platForm: "",
      //     casinoType: "",
      //   },{
      //     image: "",
      //     title: "",
      //     type: "",
      //     key: "",
      //     gameTypeCheck: "",
      //     gameType: "",
      //     platForm: "",
      //     casinoType: "",
      //   },{
      //     image: "",
      //     title: "",
      //     type: "",
      //     key: "",
      //     gameTypeCheck: "",
      //     gameType: "",
      //     platForm: "",
      //   },
     
      
    ],
  },
  
  
  {
    id: 4,
    title: "Crash",
    key: "crash",
    type: "crash",
    array: [
      {
        image: spribe,
        title: "SPRIBE",
        type: "crash",
        key: "crash",
        gameTypeCheck: "platForm",
        
        platForm: "SPRIBE",
       
      },
      {
        image: km,
        title: "KINGMAKER",
        type: "crash",
        key: "crash",
        gameTypeCheck: "platForm",
       
        platForm: "KINGMAKER",
       
      },
      {
        image: Jili,
        title: "JILI",
        type: "crash",
        key: "crash",
        gameTypeCheck: "platForm",
       
        platForm: "JILI",
       
      },
      {
        image: pp,
        title: "PP",
        type: "crash",
        key: "crash",
        gameTypeCheck: "platForm",
       
        platForm: "PP",
       
      },
     
    ],
  },
  {
    id: 4,
    title: "Fishing",
    key: "fish",
    type: "fish",
    array: [
      {
        image: Jili,
        title: "JILI",
        type: "jili",
        key: "fishing",
        gameTypeCheck: "platForm",
        gameType: "FH",
        platForm: "JILI",
      },
      {
        image: fc,
        title: "FC",
        type: "fc",
        key: "fishing",
        gameTypeCheck: "platForm",
        gameType: "FH",
        platForm: "FC",
      },
     
      {
        image: jdb,
        title: "JDB",
        type: "jdb",
        key: "fishing",
        gameTypeCheck: "platForm",
        gameType: "FH",
        platForm: "JDB",
      },
    
      {
        image: yl,
        title: "YL",
        type: "yl",
        key: "fishing",
        gameTypeCheck: "platForm",
        gameType: "FH",
        platForm: "YL",
      },
      {
        image: yesbingo,
        title: "Yes Bingo",
        type: "yesbingo",
        key: "fishing",
        gameTypeCheck: "platForm",
        gameType: "FH",
        platForm: "YESBINGO",
      },
      {
        image: sg,
        title: "Spade",
        type: "spade",
        key: "fishing",
        gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "SPADE",
      },
      // {
      //   image: fastspin,
      //   title: "FASTSPIN",
      //   type: "FastSpin",
      //   key: "fishing",
      //   gameTypeCheck: "platForm",
      //   gameType: "FH",
      //   platForm: "FASTSPIN",
      // },
       {
        image: "",
        title: "",
        type: "",
        key: "",
        gameTypeCheck: "",
        gameType: "",
        platForm: "",
        casinoType: "",
      },
    ],
  },
  
   {
    id: 6,
    title: "Arcade",
    key: "arcade",
    titlebn: "স্পোর্টসবুক",
    array: [
      {
        image: esport,
        title: "E1sports",
        type: "e1sports",
        key: "esports",
        gameTypeCheck: "platForm",
        gameType: "ESPORTS",
        platForm: "E1SPORT",
        casinoType:"E1-ESPORTS-001"
      },
      // {
      //   image: rich88,
      //   title: "RICH88",
      //   type: "RICH88",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "PP",
      // },{
      //   image: cq9,
      //   title: "CQ9",
      //   type: "CQ9",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "FH",
      //   platForm: "JDB",
      //   casinoType: "JDB-FISH-009",
      // },
      // {
      //   image: pp,
      //   title: "PP",
      //   type: "pp",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "PP",
      // },
      // {
      //   image: fc,
      //   title: "FC",
      //   type: "fc",
      //   key: "fish",
      //   gameTypeCheck: "platForm",
      //   gameType: "EGAME",
      //   platForm: "FC",
      // },
      {
        image: yl,
        title: "YL",
        type: "yl",
        key: "slot",
        gameTypeCheck: "platForm",
        gameType: "EGAME",
        platForm: "YL",
      },
      {
        image: sg,
        title: "Spade",
        type: "spade",
        key: "slot",
        gameTypeCheck: "platForm",
        gameType: "EGAME",
        platForm: "SPADE",
      },
      // {
      //   image: ps,
      //   title: "PLAYSTAR",
      //   type: "PLAYSTAR",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "PP",
      // },
      // {
      //   image: ka,
      //   title: "KA",
      //   type: "KA",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "FH",
      //   platForm: "JDB",
      //   casinoType: "JDB-FISH-011",
      // },
    ],
  },
  {
    id: 7,
    title: "Lottery",
    key: "lottery",
    titlebn: "খেলাধুলা",
    array: [
      

      
      {
        image: number,
        title: "NUMBER",
        type: "number",
        key: "lottery",
        gameTypeCheck: "platForm",
        gameType: "TABLE",
        platForm: "KINGMAKER",
        casinoType: "KINGMAKER-TABLE-009",
      },
      {
        image: "",
        title: "",
        type: "",
        key: "",
        gameTypeCheck: "",
        gameType: "",
        platForm: "",
        casinoType: "",
      }, {
        image: "",
        title: "",
        type: "",
        key: "",
        gameTypeCheck: "",
        gameType: "",
        platForm: "",
        casinoType: "",
      },
      
      {
        image: "",
        title: "",
        type: "",
        key: "",
        gameTypeCheck: "",
        gameType: "",
        platForm: "",
        casinoType: "",
      },
    ],
  },
];
export const HotCasinoData = [
 
  {
    id: 11,
    icons: pmegasicbo,
    title: "Mega Sic Bo",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-025",
  },
  {
    id: 12,
    icons: pmegawheel,
    title: "Mega Wheel",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-038",
  },
  {
    id: 13,
    icons: pmegaroll,
    title: "Mega Roulette",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-039",
  },
  {
    id: 14,
    icons: pbaccarat,
    title: "Mega Baccarat",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-061",
  },
  {
    id: 15,
    icons: pdt,
    title: "Dragon Tiger",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-042",
  },
  {
    id: 16,
    icons: ppbacarrat2,
    title: "Baccarat 2",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-002",
  },
  {
    id: 17,
    icons: ppsicbo,
    title: "SicBo Deluxe",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-003",
  },
  {
    id: 17,
    icons: pthilo,
    title: "Hi-Lo",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-014",
  },
  {
    id: 17,
    icons: ptspinwin,
    title: "Spin a Win",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-015",
  },
  {
    id: 17,
    icons: playtechteenpatti,
    title: "Teen Patti Live",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-087",
  },

  {
    id: 18,
    icons: bgroll,
    title: "Roulette",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "BG",
    gameType: "LIVE",
    casinoType: "BG-LIVE-003",
  },
  {
    id: 19,
    icons: bgsicbo,
    title: "Sicbo",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "BG",
    gameType: "LIVE",
    casinoType: "BG-LIVE-004",
  },
  {
    id: 20,
    icons: bgspeedsicbo,
    title: "SpeedSicBo",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "BG",
    gameType: "LIVE",
    casinoType: "BG-LIVE-012",
  },
  {
    id: 20,
    icons: evosupersicbo,
    title: "Super Sic Bo",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-005",
  },
  {
    id: 20,
    icons: pcrazytime,
    title: "Crazy Time",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-006",
  },
  {
    id: 20,
    icons: pmonopoly,
    title: "MONOPOLY",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-007",
  },
  {
    id: 20,
    icons: evolightdice,
    title: "Lightning Dice",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-011",
  },
  {
    id: 20,
    icons: pcoinflip,
    title: "Crazy Coin Flip",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-014",
  },
  {
    id: 20,
    icons: evoteenpatti,
    title: "Teen Patti",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-026",
  },
  {
    id: 20,
    icons: evoligtroullete,
    title: "Lightning Roulette",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-040",
  },
  {
    id: 20,
    icons: evospeedroullete,
    title: "Speed Roulette",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-078",
  },


];
export const AuraCasinoData = [
  {
    image: "../assets/images/poker/aviator.webp",
    title: "Aviator",
    gameCode: "9999",
    legacy: "67722-1_8",
  },

  {
    image: "../assets/images/poker/avaitrix.webp",
    title: "Aviatrix",
    gameCode: "9999",
    legacy: "67722-2_8",
  },
  {
    image: "../assets/images/poker/1.webp",
    title: "7 up & 7 down",
    gameCode: "98789",
    legacy: "98789_2",
  },
  {
    image: "../assets/images/poker/2.webp",
    title: "Live Teenpati",
    gameCode: "56767",
    legacy: "56767_2",
  },
  {
    image: "../assets/images/poker/3.webp",
    title: "Teenpatti T20",
    gameCode: "56768",
    legacy: "56768_2",
  },
  {
    image: "../assets/images/poker/4.webp",
    title: "Dragon Tiger",
    gameCode: "98790",
    legacy: "98790_2",
  },
  {
    image: "../assets/images/poker/5.webp",
    title: "Hi Low",
    gameCode: "56968",
    legacy: "56968_2",
  },
  {
    image: "../assets/images/poker/6.webp",
    title: "Worli Matka",
    gameCode: "92037",
    legacy: "92037_2",
  },
  {
    image: "../assets/images/poker/9.webp",
    title: "Andhar Bahar",
  gameCode: "87564",
  legacy: "87564_2",
  },
  {
    image: "../assets/images/poker/10.webp",
    title: "Amar Akbar Anthony",
    gameCode: "98791",
    legacy: "98791_2",
  },
];
export const validationRules = {
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  password: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,15}$/,
  newPass: /^[a-zA-Z0-9]{8,15}$/,
  newPassMessage:
    "Password must contain uppercase and lowercase characters, numbers and must be minimum 8 character long (without special character).",
  passwordMessage:
    "Password must contain uppercase and lowercase characters, numbers, special character and must be minimum 8 character long.",
  characters: /^[a-zA-Z_ ]*$/,
  numbers: /^[0-9]*$/,
  removeWhitespace: /^[a-zA-Z0-9]+$/,
  numberNew: /^[0-9]*$/,
  numberWithDot: /^\d*(\.\d{0,10})?$/,
};
export const preventMaxInput = (e) => {
  e.target.value = e.target.value.trimStart();
  e.target.value = e.target.value.replace(/  +/g, " ");
};
export const AmountArray = [
  { id: 1, amount: 300 },
  { id: 2, amount: 500 },
  { id: 3, amount: 1000 },
  { id: 4, amount: 5000 },
  { id: 5, amount: 10000 },
  { id:6, amount: 15000 },
  { id: 7, amount: 20000 },
  { id: 8, amount: 25000 },
];