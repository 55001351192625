import { FaWallet, FaRegUser } from "react-icons/fa";
import { BiWalletAlt, BiTransfer } from "react-icons/bi";
import { AiOutlineDollarCircle } from "react-icons/ai";
import { CgKeyhole } from "react-icons/cg";
import { FaRegClock } from "react-icons/fa";
import { TbTransferIn } from "react-icons/tb";
import { IoMdSettings } from "react-icons/io";
import { MdOutlineAccountBalance } from "react-icons/md";
import HomeIcon from "../assets/images/home.png";
import casinoIcon from "../assets/images/casino.png";
import sportIcon from "../assets/images/sports.png";
import leadgeIcon from "../assets/images/leadge.png";
import betsIcon from "../assets/images/mybets.png";

import banner1 from "../assets/images/banner1.webp";
import banner2 from "../assets/images/banner2.webp";
import banner3 from "../assets/images/banner3.webp";

export const sidebarData = [
  {
    firstLevel: [,
      // {id: 1,menu: "Upline Whatsapp Number",icon: <MdOutlineWhatsapp />,link: "/upline-whatsapp-number"},
      {
        id: 2,
        menu: "Balance Overview",
        icon: <FaWallet />,
        link: "/balance-overview",
        menubn: "ব্যালেন্স ওভারভিউ",
      },
      {
        id: 3,
        menu: "Account Statement",
        icon: <MdOutlineAccountBalance />,
        link: "/account-statement",
        menubn: "অ্যাকাউন্ট বিবৃতি",
      },
      {
        id: 4,
        menu: "Current Bets",
        icon: <BiWalletAlt />,
        link: "/current-bets",
      },
      {
        id: 5,
        menu: "Bets History",
        icon: <FaRegClock />,
        link: "/bets-history",
      },
      {
        id: 6,
        menu: "Profit & Loss",
        icon: <AiOutlineDollarCircle />,
        link: "/profit-and-loss",
        menubn: "লাভ ক্ষতি",
      },
      {
        id: 7,
        menu: "Active Log",
        icon: <CgKeyhole />,
        link: "/active-log",
        menubn: "সক্রিয় লগ",
      },
      {
        id: 8,
        menu: "My Profile",
        icon: <FaRegUser />,
        link: "/my-profile",
        menubn: "আমার প্রোফাইল",
      },
    ],
  },

  {
    secondLevel: [
      {
        id: 8,
        menu: "P2P Transfer",
        icon: <BiTransfer />,
        link: "/p2p-transfer",
      },
      {
        id: 9,
        menu: "P2P Transfer log",
        icon: <TbTransferIn />,
        link: "/p2p-transfer-log",
      },
    ],
  },

  {
    thirdLevel: [
      { id: 10, menu: "Setting", icon: <IoMdSettings />, link: "/setting" },
    ],
  },
];

export const bottomNavigation = [
  {
    id: "1",
    menu: "Home",
    icon: HomeIcon,
    link: "/",
  },
  {
    id: "2",
    menu: "Casino",
    icon: casinoIcon,
    link: "/casino",
  },
  {
    id: "3",
    menu: "Sports",
    icon: sportIcon,
    link: "/sports",
  },
  {
    id: "4",
    menu: "Leagues",
    icon: leadgeIcon,
    link: "/leagues",
  },
  {
    id: "5",
    menu: "My Bets",
    icon: betsIcon,
  },
];

export const gamesLiveArray = [
  // {
  //   img: "../assets/images/aviator.webp",
  //   name: "Aviator",
  //   platForm: "SPRIBE",
  //   gameType: "EGAME",
  //   casinoType: "SPRIBE-EGAME-001",
  //   cateogry: "EVO",
  //   type: "GAME-SHOWS",
  // },
  {
    img: "../assets/images/game-shows/GAME-SHOW-1.webp",
    name: "XXXtreme Lightning roulette",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-205",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-2.webp",
    name: "Cash or Crash",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-175",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-3.webp",
    name: "Funky Time",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-183",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-4.webp",
    name: "Dead or Alive Saloon",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-176",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-5.webp",
    name: "Crazy Time",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-006",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-6.webp",
    name: "Lightning Dice",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-011",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },

  {
    img: "../assets/images/game-shows/GAME-SHOW-7.webp",
    name: "Lightning Roulette",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-040",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-8.webp",
    name: "Lightning Fever",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-133",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-9.webp",
    name: "Extra Chilli",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-168",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-10.webp",
    name: "Imperial Quest",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-169",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-11.webp",
    name: "Monopoly Live",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-007",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-12.webp",
    name: "Monopoly Big Baller",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-018",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-13.webp",
    name: "Crazy Coin Flip",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-014",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-14.webp",
    name: "Mega Ball",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-010",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-15.webp",
    name: "Deal or Nodeal",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-025",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/EVOLUTION-LIVE-246.png",
    name: "Gonzo's Treasure Map",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-246",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/PP-LIVE-075.webp",
    name: "Auto Mega Roulette",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-075",
    cateogry: "PP",
    type: "GAME-SHOWS",
  },

  

  {
    img: "../assets/images/game-shows/GAME-SHOW-16.webp",
    name: "Wounderland",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-011",
    cateogry: "PT",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-17.webp",
    name: "Shweet Bonanza Candy Land",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-044",
    cateogry: "PP",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-18.webp",
    name: "Spin a Win Wild live",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-015",
    cateogry: "PT",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-19.webp",
    name: "Spin and Win Wild B X2",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-088",
    cateogry: "PT",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-20.webp",
    name: "The Greatest Cards Show",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-086",
    cateogry: "PT",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-21.webp",
    name: "Mega Wheel",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-038",
    cateogry: "PP",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-22.webp",
    name: "Mega Fire Blaze Roulette",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-028",
    cateogry: "PT",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-23.webp",
    name: "Mega Roulette 500x",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-039",
    cateogry: "PP",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-24.webp",
    name: "Mega Baccarat",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-061",
    cateogry: "PP",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-25.webp",
    name: "Quantum Auto Roulette",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-026",
    cateogry: "PT",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-26.webp",
    name: "Power Roulette",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-055",
    cateogry: "PP",
    type: "GAME-SHOWS",
  },

  {
    img: "../assets/images/game-shows/GAME-SHOW-27.webp",
    name: "Sicbo Delux",
    gameType: "LIVE",
    platForm: "PT",
    casinoType: "PT-LIVE-003",
    cateogry: "PT",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-28.webp",
    name: "Mega Sicbo",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-025",
    cateogry: "PP",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-29.webp",
    name: "Bet on Dragon Tiger",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-042",
    cateogry: "PP",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-30.webp",
    name: "Hi-lo Club",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-014",
    cateogry: "PT",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/PP-LIVE-077.webp",
    name: "Lucky 6 Roulette",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-077",
    cateogry: "PP",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-32.webp",
    name: "Italian Cashback Blackjack",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-031",
    cateogry: "PT",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/PP-LIVE-076.webp",
    name: "Lucky 6 Roulette",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-076",
    cateogry: "PP",
    type: "GAME-SHOWS",
  },


  // {
  //   img: "../assets/images/game-shows/GAME-SHOW-31.webp",
  //   name: "Quantum BlackJack Plus",
  //   platForm: "PT",
  //   gameType: "LIVE",
  //   casinoType: "PT-LIVE-029",
  //   cateogry: "PT",
  //   type: "GAME-SHOWS",
  // },
  

  {
    img: "../assets/images/Baccarat/BACCARAT-1.webp",
    name: "Golden Wealth Baccarat",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-179",
    cateogry: "EVO",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-2.webp",
    name: "Baccarat no commission",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-038",
    cateogry: "EVO",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-3.webp",
    name: "Speed Baccarat",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-159",
    cateogry: "EVO",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-4.webp",
    name: "Speed Baccarat",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-134",
    cateogry: "EVO",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-5.webp",
    name: "Lightning Baccarat First Person",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-255",
    cateogry: "EVO",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-6.webp",
    name: "Speed Baccarat",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-210",
    cateogry: "EVO",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/EVOLUTION-LIVE-245.png",
    name: "Prosperity Tree Baccarat",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-245",
    cateogry: "EVO",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/EVOLUTION-LIVE-244.png",
    name: "First Person Prosperity Tree Baccarat",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-244",
    cateogry: "EVO",
    type: "BACCARAT",
  },



  {
    img: "../assets/images/Baccarat/BACCARAT-7.webp",
    name: "Baccarat Classic",
    table:'1',
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-001",
    cateogry: "SEXY",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-8.webp",
    name: "Baccarat Classic",
    table:'2',
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-001",
    cateogry: "SEXY",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-9.webp",
    name: "Baccarat Classic",
    table:'3',
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-001",
    cateogry: "SEXY",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-10.webp",
    name: "Baccarat Classic",
    table:'4',
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-001",
    cateogry: "SEXY",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-11.webp",
    name: "Baccarat Classic",
    table:'5',
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-001",
    cateogry: "SEXY",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-12.webp",
    name: "Baccarat Classic",
    table:'6',
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-001",
    cateogry: "SEXY",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/PT-LIVE-109.png",
    name: "Bet on Baccarat Live",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-109",
    cateogry: "PT",
    type: "BACCARAT",
  },
  // {
  //   img: "../assets/images/Baccarat/BACCARAT-13.webp",
  //   name: "Bet on Baccarat Live",
  //   platForm: "PT",
  //   gameType: "LIVE",
  //   casinoType: "PT-LIVE-005",
  //   cateogry: "PT",
  //   type: "BACCARAT",
  // },
  {
    img: "../assets/images/Baccarat/BACCARAT-14.webp",
    name: "Baccarat 1",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-001",
    cateogry: "PT",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-15.webp",
    name: "Baccarat 3",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-001",
    cateogry: "PT",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-16.webp",
    name: "Speed Baccarat 1",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-010",
    cateogry: "PP",
    type: "BACCARAT",
  },
  // {
  //   img: "../assets/images/Baccarat/BACCARAT-17.webp",
  //   name: "Baccarat Classic",
  //   platForm: "VENUS",
  //   gameType: "LIVE",
  //   casinoType: "VN-LIVE-001",
  //   cateogry: "VENUS",
  //   type: "BACCARAT",
  // },
  // {
  //   img: "../assets/images/Baccarat/BACCARAT-18.webp",
  //   name: "Baccarat Classic",
  //   platForm: "VENUS",
  //   gameType: "LIVE",
  //   casinoType: "VN-LIVE-001",
  //   cateogry: "VENUS",
  //   type: "BACCARAT",
  // },
  // {
  //   img: "../assets/images/Baccarat/BACCARAT-19.webp",
  //   name: "Baccarat Classic",
  //   platForm: "VENUS",
  //   gameType: "LIVE",
  //   casinoType: "VN-LIVE-001",
  //   cateogry: "VENUS",
  //   type: "BACCARAT",
  // },
  // {
  //   img: "../assets/images/Baccarat/BACCARAT-20.webp",
  //   name: "Baccarat Classic",
  //   platForm: "VENUS",
  //   gameType: "LIVE",
  //   casinoType: "VN-LIVE-001",
  //   cateogry: "VENUS",
  //   type: "BACCARAT",
  // },
  // {
  //   img: "../assets/images/Baccarat/BACCARAT-21.webp",
  //   name: "Baccarat Classic",
  //   platForm: "VENUS",
  //   gameType: "LIVE",
  //   casinoType: "VN-LIVE-001",
  //   cateogry: "VENUS",
  //   type: "BACCARAT",
  // },
  // {
  //   img: "../assets/images/Baccarat/BACCARAT-22.webp",
  //   name: "Baccarat Classic",
  //   platForm: "VENUS",
  //   gameType: "LIVE",
  //   casinoType: "VN-LIVE-001",
  //   cateogry: "VENUS",
  //   type: "BACCARAT",
  // },
  {
    img: "../assets/images/Baccarat/BACCARAT-24.webp",
    name: "Speed Baccarat 1",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-013",
    cateogry: "PP",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-25.webp",
    name: "Super 8 Baccarat",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-045",
    cateogry: "PP",
    type: "BACCARAT",
  },
  // {
  //   img: "../assets/images/Baccarat/BACCARAT-26.webp",
  //   name: "Speed Baccarat",
  //   platForm: "VENUS",
  //   gameType: "LIVE",
  //   casinoType: "VN-LIVE-002",
  //   cateogry: "VENUS",
  //   type: "BACCARAT",
  // },
  // {
  //   img: "../assets/images/Baccarat/BACCARAT-27.webp",
  //   name: "Baccarat Insurance",
  //   platForm: "SEXY",
  //   gameType: "LIVE",
  //   casinoType: "MX-LIVE-002",
  //   cateogry: "SEXY",
  //   type: "BACCARAT",
  // },
  // {
  //   img: "../assets/images/Baccarat/BACCARAT-28.webp",
  //   name: "Baccarat Insurance",
  //   platForm: "VENUS",
  //   gameType: "LIVE",
  //   casinoType: "VN-LIVE-003",
  //   cateogry: "VENUS",
  //   type: "BACCARAT",
  // },
  // Baccarat End

  // Roulette Start
  {
    img: "../assets/images/Roulette/ROULETTE-1.webp",
    name: "Roulette Live",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-001",
    cateogry: "EVO",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-2.webp",
    name: "Roulette Live",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-080",
    cateogry: "EVO",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-3.webp",
    name: "Roulette Live",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-078",
    cateogry: "EVO",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-4.webp",
    name: "Roulette Live",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-079",
    cateogry: "EVO",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-5.webp",
    name: "Instant Roulette",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-008",
    cateogry: "EVO",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-6.webp",
    name: "Roulette First Person",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-013",
    cateogry: "EVO",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-7.webp",
    name: "Amercian Roulette Live",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-020",
    cateogry: "EVO",
    type: "ROULETTE",
  },
  // {
  //   img: "../assets/images/Roulette/ROULETTE-8.webp",
  //   name: "Roulette Live",
  //   platForm: "EVOLUTION",
  //   gameType: "LIVE",
  //   casinoType: "EVOLUTION-LIVE-001",
  //   cateogry: "EVO",
  //   type: "ROULETTE",
  // },
  {
    img: "../assets/images/Roulette/ROULETTE-9.webp",
    name: "Roulette",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-009",
    cateogry: "SEXY",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-10.webp",
    name: "Roulette",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-029",
    cateogry: "PP",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-11.webp",
    name: "Auto Roulette",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-026",
    cateogry: "PP",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-12.webp",
    name: "Speed Auto Roulette",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-060",
    cateogry: "PP",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/PT-LIVE-102.png",
    name: "Speed Roulette 2",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PP-LIVE-102",
    cateogry: "PP",
    type: "ROULETTE",
  },
  // {
  //   img: "../assets/images/Roulette/ROULETTE-13.webp",
  //   name: "Roulette",
  //   platForm: "VENUS",
  //   gameType: "LIVE",
  //   casinoType: "VN-LIVE-009",
  //   cateogry: "VENUS",
  //   type: "ROULETTE",
  // },
  {
    img: "../assets/images/Roulette/ROULETTE-14.webp",
    name: "Speed Roulette",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-027",
    cateogry: "PP",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-15.webp",
    name: "Azure Roulette",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-028",
    cateogry: "PP",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-16.webp",
    name: "American Roulette Live",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-021",
    cateogry: "PT",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-17.webp",
    name: "Deutsches Roulette Live",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-022",
    cateogry: "PT",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-18.webp",
    name: "Roulette Live",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-025",
    cateogry: "PT",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-19.webp",
    name: "Football French Roulette",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-023",
    cateogry: "PT",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-20.webp",
    name: "Football Roulette",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-024",
    cateogry: "PT",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-21.webp",
    name: "Spread Bets Roulette",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-027",
    cateogry: "PT",
    type: "ROULETTE",
  },

  {
    img: "../assets/images/Dice/DICE-1.webp",
    name: "Bac Bo Live",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-172",
    cateogry: "EVO",
    type: "DICE",
  },
  {
    img: "../assets/images/Dice/DICE-2.webp",
    name: "Super Sic Bo",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-005",
    cateogry: "EVO",
    type: "DICE",
  },
  {
    img: "../assets/images/Dice/EVOLUTION-LIVE-259.png",
    name: "First Person Super Sic Bo",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-259",
    cateogry: "EVO",
    type: "DICE",
  },
  {
    img: "../assets/images/Dice/EVOLUTION-LIVE-260.png",
    name: "Instant Super Sic Bo",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-260",
    cateogry: "EVO",
    type: "DICE",
  },
  
  {
    img: "../assets/images/Dice/DICE-3.webp",
    name: "Craps Live",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-003",
    cateogry: "EVO",
    type: "DICE",
  },
  {
    img: "../assets/images/Dice/DICE-4.webp",
    name: "Experor Sic Bo",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-211",
    cateogry: "EVO",
    type: "DICE",
  },
  {
    img: "../assets/images/Dice/DICE-5.webp",
    name: "Extra Sic bo",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-016",
    cateogry: "SEXY",
    type: "DICE",
  },

  {
    img: "../assets/images/Dice/PP-LIVE-079.webp",
    name: "Sic bo",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-079",
    cateogry: "PP",
    type: "DICE",
  },
  {
    img: "../assets/images/Dice/PT-LIVE-099.png",
    name: "Speed SicBo",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-099",
    cateogry: "PT",
    type: "DICE",
  },
  // {
  //   img: "../assets/images/Dice/DICE-6.webp",
  //   name: "Red Blue Duel",
  //   platForm: "SEXYBCRT",
  //   gameType: "LIVE",
  //   casinoType: "MX-LIVE-010",
  //   cateogry: "SEXY",
  //   type: "DICE",
  // },
  // {
  //   img: "../assets/images/Dice/DICE-7.webp",
  //   name: "Sic Bo",
  //   platForm: "VENUS",
  //   gameType: "LIVE",
  //   casinoType: "VN-LIVE-007",
  //   cateogry: "VENUS",
  //   type: "DICE",
  // },

  // Tradiotional Start
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-1.webp",
    name: "Super Andhar Bahar",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-204",
    cateogry: "EVO",
    type: "TRADITIONAL",
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-2.webp",
    name: "Fan Tan",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-178",
    cateogry: "EVO",
    type: "TRADITIONAL",
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-4.webp",
    name: "Dragon Tiger",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-004",
    cateogry: "EVO",
    type: "TRADITIONAL",
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-3.webp",
    name: "Dragon Tiger",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-077",
    cateogry: "EVO",
    type: "TRADITIONAL",
  },

  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-5.webp",
    name: "Teen Patti Live",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-026",
    cateogry: "EVO",
    type: "TRADITIONAL",
  },

  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-6.webp",
    name: "Dragon Tiger",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-006",
    cateogry: "SEXY",
    type: "TRADITIONAL",
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-7.webp",
    name: "Dragon Tiger",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-006",
    cateogry: "SEXY",
    type: "TRADITIONAL",
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-10.webp",
    name: "Dragon Tiger",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-002",
    cateogry: "PT",
    type: "TRADITIONAL",
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-13.webp",
    name: "Dragon Tiger",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-042",
    cateogry: "PP",
    type: "TRADITIONAL",
  },

  // {
  //   img: "../assets/images/TraditionalGames/TRADINATIONAL-15.webp",
  //   name: "Dragon Tiger",
  //   platForm: "VN",
  //   gameType: "LIVE",
  //   casinoType: "VN-LIVE-006",
  //   cateogry: "VENUS",
  //   type: "TRADITIONAL",
  // },
  // {
  //   img: "../assets/images/TraditionalGames/TRADINATIONAL-16.webp",
  //   name: "Dragon Tiger",
  //   platForm: "VN",
  //   gameType: "LIVE",
  //   casinoType: "VN-LIVE-006",
  //   cateogry: "VENUS",
  //   type: "TRADITIONAL",
  // },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-9.webp",
    name: "Extra Teen Patti",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-012",
    cateogry: "SEXY",
    type: "TRADITIONAL",
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-11.webp",
    name: "Andhar Bahar Live",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-004",
    cateogry: "PT",
    type: "TRADITIONAL",
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-14.webp",
    name: "Andhar Bahar",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-043",
    cateogry: "PP",
    type: "TRADITIONAL",
  },

  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-8.webp",
    name: "Teen Patti",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-011",
    cateogry: "SEXY",
    type: "TRADITIONAL",
  },
  {
    img: "../assets/images/DICE/MX-LIVE-017_SEXY.png",
    name: "xocdia",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-017",
    cateogry: "SEXY",
    type: "TRADITIONAL",
  },
  // {
  //   img: "../assets/images/TraditionalGames/TRADINATIONAL-12.webp",
  //   name: "Teen Patti",
  //   platForm: "EVOLUTION",
  //   gameType: "LIVE",
  //   casinoType: "EVOLUTION-LIVE-026",
  //   cateogry: "PT",
  //   type: "TRADITIONAL",
  // },
  // Tradiotional End

  // {
  //   img: "../assets/images/CardGames/EVOLUTION-LIVE-181.webp",
  //   name: "Lightning Black Jack",
  //   platForm: "EVOLUTION",
  //   gameType: "LIVE",
  //   casinoType: "EVOLUTION-LIVE-181",
  //   cateogry: "EVOLUTION",
  //   type: "GAME-CARD",
  // },
  {
    img: "../assets/images/CardGames/EVOLUTION-LIVE-208.webp",
    name: "Lightning Black Jack First Person",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-208",
    cateogry: "EVOLUTION",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/EVOLUTION-LIVE-227.png",
    name: "Lightning Black Jack First Person",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-227",
    cateogry: "EVOLUTION",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/EVOLUTION-LIVE-009.webp",
    name: "Triple Card Poker",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-009",
    cateogry: "EVOLUTION",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/EVOLUTION-LIVE-012.webp",
    name: "Infinite Black Jack",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-012",
    cateogry: "EVOLUTION",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/PT-LIVE-006.webp",
    name: "Alexandrite Blackjack",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-007",
    cateogry: "PT",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/PP-LIVE-040.webp",
    name: "One Blackjack",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-040",
    cateogry: "PP",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/PP-LIVE-041.webp",
    name: "ONE Blackjack 2 - Ruby",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-041",
    cateogry: "PP",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/PT-LIVE-008.webp",
    name: "Majority Rules Speed Blackjack",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-008",
    cateogry: "PT",
    type: "GAME-CARD",
  },
























  // {
  //   img: "../assets/images/CardGames/EVOLUTION-LIVE-002.webp",
  //   name: "Black Jack A",
  //   platForm: "EVOLUTION",
  //   gameType: "LIVE",
  //   casinoType: "EVOLUTION-LIVE-002",
  //   cateogry: "EVOLUTION",
  //   type: "GAME-CARD",
  // },
  // {
  //   img: "../assets/images/CardGames/EVOLUTION-LIVE-015.webp",
  //   name: "Casino Hold'em",
  //   platForm: "EVOLUTION",
  //   gameType: "LIVE",
  //   casinoType: "EVOLUTION-LIVE-015",
  //   cateogry: "EVOLUTION",
  //   type: "GAME-CARD",
  // },
  

 
  // {
  //   img: "../assets/images/CardGames/EVOLUTION-LIVE-024.webp",
  //   name: "Caribbean Stud Poker",
  //   platForm: "EVOLUTION",
  //   gameType: "LIVE",
  //   casinoType: "EVOLUTION-LIVE-024",
  //   cateogry: "EVOLUTION",
  //   type: "GAME-CARD",
  // },
  
  // {
  //   img: "../assets/images/CardGames/PT-LIVE-007.webp",
  //   name: "All bets Blackjack",
  //   platForm: "PT",
  //   gameType: "LIVE",
  //   casinoType: "PT-LIVE-007",
  //   cateogry: "PT",
  //   type: "GAME-CARD",
  // },
 
  

  
  {
    img: "../assets/images/CardGames/PT-LIVE-010.webp",
    name: "Unlimited Blackjack",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-010",
    cateogry: "PT",
    type: "GAME-CARD",
  },

  {
    img: "../assets/images/CardGames/PT-LIVE-016.webp",
    name: "3 Card Brag",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-016",
    cateogry: "PT",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/PT-LIVE-017.webp",
    name: "Casino Hold AEM",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-017",
    cateogry: "PT",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/PT-LIVE-019.webp",
    name: "Bet on Poker Live",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-019",
    cateogry: "PT",
    type: "GAME-CARD",
  },
  // {
  //   img: "../assets/images/CardGames/PT-LIVE-009.webp",
  //   name: "Sette E Mezzo",
  //   platForm: "PT",
  //   gameType: "LIVE",
  //   casinoType: "PT-LIVE-009",
  //   cateogry: "PT",
  //   type: "GAME-CARD",
  // },
  // Table Start  (casino Done) -------
  
  {
    img: "../assets/images/Table/TABLE-2.webp",
    name: "Cash Rocket",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-041",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-3.webp",
    name: "Ludo",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-060",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-066.webp",
    name: "Coin Pusher",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-066",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-4.webp",
    name:"7 Up 7 Down",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-028",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-5.webp",
    name: "sicbo",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-015",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
 
  {
    img: "../assets/images/Table/TABLE-6.webp",
    name:"Coin Toss",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-036",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-7.webp",
    name: "Cards Hi Lo",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-037",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/NETENT-TABLE-001.png",
    name: "Classic Blackjack",
    platForm: "NETENT",
    gameType: "TABLE",
    casinoType: "NETENT-TABLE-001",
    cateogry: "NETENT",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-9.webp",
    name: "Card Matka",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-022",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-021.webp",
    name: "Number Matka",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-021",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-032.webp",
    name: "Andar Bahar",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-032",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-12.webp",
    name: "Bonus Dice",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-043",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-13.webp",
    name: "32 Cards",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-039",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-040.webp",
    name: "Plinko",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-040",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-067.webp",
    name: "Cockfighting Arena",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-067",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-16.webp",
    name: "Colour Game",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-050",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },

  
 
  {
    img: "../assets/images/Table/KM-TABLE-053.webp",
    name: "Marble Knockout",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-053",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
 
  {
    img: "../assets/images/Table/KM-TABLE-048.webp",
    name: "Horse Racing",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-048",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-055.webp",
    name: "KM Virtual Greyhound Racing",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-055",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-058.webp",
    name: "KM Virtual Treadmill Racing",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-058",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-8.webp",
    name: "5 Card Poker",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-049",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },

  
  {
    img: "../assets/images/Table/TABLE-15.webp",
    name: "keno",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-018",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-045.webp",
    name: "Monkey King Roulette",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-045",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  
  {
    img: "../assets/images/Table/KM-TABLE-038.webp",
    name: "Blackjack",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-038",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-042.webp",
    name: "Minesweeper",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-042",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-035.webp",
    name: "Bola Golek",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-035",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-030.webp",
    name: "jhandimunda",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-030",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },

  // Table End KINGMAKER (casino Done) -------


  // Table Start JILI (casino Done) -------
  {
    img: "../assets/images/JILI/JILI-TABLE-021.webp",
    name: "Ludo Quick",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-021",
    cateogry: "JILI",
    type: "TABLE",
  },
  
  {
    img: "../assets/images/JILI/JILI-TABLE-014.webp",
    name: "Baccarat",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-014",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-017.webp",
    name: "Sic Bo",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-017",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-016.webp",
    name: "TeenPatti 20-20",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-016",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-018.webp",
    name: "Super Bingo",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-018",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-015.webp",
    name: "Fortune Bingo",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-015",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-011.webp",
    name: "7up7down",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-011",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-013.webp",
    name: "Callbreak Quick",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-013",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-012.webp",
    name: "Dragon & Tiger",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-012",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-010.webp",
    name: "TeenPatti Joker",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-010",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-008.webp",
    name: "iRich Bingo",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-008",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-009.webp",
    name: "Callbreak",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-009",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-003.webp",
    name: "Andar Bahar",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-003",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-004.webp",
    name: "Rummy",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-004",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-005.webp",
    name: "NumberKing",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-005",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-027.webp",
    name: "Golden Land",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-027",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-006.webp",
    name: "Poker King",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-006",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-007.webp",
    name: "Big small",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-007",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-028.webp",
    name: "PAPPU",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-028",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-035.webp",
    name: "Limbo",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-035",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-036.webp",
    name: "wheel",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-036",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-039.webp",
    name: "Color Prediction",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-039",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-032.webp",
    name: "HILO",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-032",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-030.webp",
    name: "Go Rush",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-030",
    cateogry: "JILI",
    type: "TABLE",
  },
 
  {
    img: "../assets/images/JILI/JILI-TABLE-002.webp",
    name: "AK47",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-002",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/JILI-TABLE-047.png",
    name: "Crash Bonus",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-047",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/JILI-TABLE-042.png",
    name: "Mini Flush",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-042",
    cateogry: "JILI",
    type: "TABLE",
  }, {
    img: "../assets/images/Table/JILI-TABLE-033.png",
    name: "Mines",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-033",
    cateogry: "JILI",
    type: "TABLE",
  }, {
    img: "../assets/images/Table/JILI-TABLE-031.png",
    name: "Tower",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-031",
    cateogry: "JILI",
    type: "TABLE",
  },
  
  // Table End JILI (casino Done) -------

  // Other 

  {
    img: "../assets/images/Others/MX-LIVE-015_SEXY.webp",
    name: "Thai Fish Prawn Crab",
    casinoType: "MX-LIVE-015",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    cateogry: "SEXY",
    type: "OTHERS",
  },
  // {
  //   img: "../assets/images/Others/VN-LIVE-008.webp",
  //   name: "Thai Fish Prawn Crab",
  //   casinoType: "VN-LIVE-008",
  //   platForm: "VENUS",
  //   gameType: "LIVE",
  //   cateogry: "VENUS",
  //   type: "OTHERS",
  // },
  // Other
  // Slot Start PP (casino Done) -----
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-075.webp",
    name: "Mahjong Win",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-075",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-017.png",
    name: "Starburst™ XXXtreme",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-017",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-014.png",
    name: "Divine Fortune™ Megaways™",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-014",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-076.webp",
    name: "Super Hoo Hey How",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-076",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-010.png",
    name: "Finn's Golden Tavern™",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-010",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-018.png",
    name: "Twin Spin™",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-018",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-006.png",
    name: "Parthenon: Quest for Immortality™",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-006",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-177.webp",
    name: "101 Candies",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-177",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-178.webp",
    name: "Big Bang Boom",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-178",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-179.webp",
    name: "Crab Trap™",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-179",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-180.webp",
    name: "Jack and the Beanstalk Remastered",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-180",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-181.webp",
    name: "Thrill to Grill™",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-181",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-082.webp",
    name: "Football Rules",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PT-SLOT-082",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-003.webp",
    name: "777",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-003",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-004.webp",
    name: "Get Money",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-004",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-012.webp",
    name: "Over Dragon's Gate",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-012",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-002.webp",
    name: "Phoenix",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-002",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-011.webp",
    name: "Diamond Mogul",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-011",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-005.webp",
    name: "Rich Now",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-005",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-008.webp",
    name: "Dracula",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-008",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-020.webp",
    name: "Bust Treasury",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-020",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
 
  {
    img: "../assets/images/slot/PP-SLOT-002.webp",
    name: "Gates of Olympus",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-002",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-082.webp",
    name: "Sweet Bonanza",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-082",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-189.webp",
    name: "Starlight Princess",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-189",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-183.webp",
    name: "5 Lions Megaways",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-183",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-080.webp",
    name: "5 Lions Gold",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-080",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-049.webp",
    name: "Great Rhino Megaways",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-049",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-069.webp",
    name: "Fire Strike",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-069",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-067.webp",
    name: "Sweet Bonanza Xmas",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-067",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-015.webp",
    name: "Bonanza Gold",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-015",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-176.webp",
    name: "Buffalo King Megaways",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-176",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-181.webp",
    name: "The Magic Cauldron - Enchanted Brew",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-181",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-046.webp",
    name: "Fruit Party",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-046",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-187.webp",
    name: "Pyramid Bonanza",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-187",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-204.webp",
    name: "Candy Village",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-204",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-126.webp",
    name: "3 Kingdoms - Battle of Red Cliffs",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-126",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-104.webp",
    name: "5 Lions",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-104",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-072.webp",
    name: "John Hunter and the Tomb of the Scarab Queen",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-072",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-107.webp",
    name: "Joker's Jewels",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-107",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-025.webp",
    name: "Gems Bonanza",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-025",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-210.webp",
    name: "Santa's Wonderland",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-210",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-233.webp",
    name: "Wild West Gold Megaways",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-233",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-234.webp",
    name: "Spirit of Adventure",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-234",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-235.webp",
    name: "Fire Strike 2",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-235",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-236.webp",
    name: "Barn Festival",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-236",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-237.webp",
    name: "Chicken Chase",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-237",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-238.webp",
    name: "Goblin Heist Powernudge",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-238",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-239.webp",
    name: "Eye of Cleopatra",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-239",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-240.webp",
    name: "The Great Stick-Up",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-240",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-296.webp",
    name: "Aztec Blaze",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-296",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-297.webp",
    name: "Starlight Christmas",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-297",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-298.webp",
    name: "Towering Fortunes",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-298",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-299.webp",
    name: "Firebird Spirit",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-299",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-300.webp",
    name: "Pirate Golden Age",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-300",
    cateogry: "PP",
    type: "SLOT",
  },
  // Slot End PP (casino Done) -----

  // Slot Start KINGMAKER (casino Done) -----
  {
    img: "../assets/images/slot/KM-SLOT-001.webp",
    name: "Sugar Blast",
    platForm: "KINGMAKER",
    gameType: "SLOT",
    casinoType: "KM-SLOT-001",
    cateogry: "KINGMAKER",
    type: "SLOT",
  },
  // Slot End KINGMAKER (casino Done) -----

  // Slot Start DRAGOONSOFT (casino Done) -----
 
  
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-001.webp",
    name: "Coin Cat",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-001",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-015.webp",
    name: "Egypt Oracle",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-015",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-018.webp",
    name: "Rich Lion",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-018",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-017.webp",
    name: "Candy Dynasty",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-017",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-007.webp",
    name: "Doggy Wealth",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-007",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-016.webp",
    name: "Caishen Coming",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-016",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-006.webp",
    name: "Chef Lady",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-006",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-009.webp",
    name: "Maya King",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-009",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-010.webp",
    name: "Pirate King",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-010",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-013.webp",
    name: "Great Lion",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-013",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-014.webp",
    name: "Ultra Treasure",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-014",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-019.webp",
    name: "Monkey King",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-019",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  // Slot End DRAGOONSOFT (casino Done) -----

  // Slot Start SPADE (casino Done) -----
  // {
  //   img: "../assets/images/slot/SG-SLOT-106.webp",
  //   name: "Fiery Sevens Exclusive",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-106",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-074.webp",
  //   name: "Mega7",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-074",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-116.webp",
  //   name: "Legacy Of Kong Maxways",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-116",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-069.webp",
  //   name: "888",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-069",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-112.webp",
  //   name: "Fruits Mania",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-112",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-076.webp",
  //   name: "DoubleFlame",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-076",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-110.webp",
  //   name: "Space Conquest",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-110",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-081.webp",
  //   name: "Triple Panda",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-081",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-077.webp",
  //   name: "Book of Myth",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-077",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-095.webp",
  //   name: "Poker Ways",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-095",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },





  // {
  //   img: "../assets/images/slot/SG-SLOT-086.webp",
  //   name: "Mayan Gems",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-086",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-075.webp",
  //   name: "JokersTreasure",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-075",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-030.webp",
  //   name: "5FortuneSA",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-030",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-103.webp",
  //   name: "Sexy Vegas",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-103",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-107.webp",
  //   name: "Lucky Koi Exclusive",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-107",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-082.webp",
  //   name: "Gold Panther",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-082",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-032.webp",
  //   name: "Golden Lotus SE",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-032",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-105.webp",
  //   name: "Royale House",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-105",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // Slot End SPADE (casino Done) -----

  // Slot Start PT (casino Done) -----
 
  {
    img: "../assets/images/slot/PT-SLOT-070.webp",
    name: "Heart of the Frontier",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-070",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-071.webp",
    name: "Thai Paradise",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-071",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-072.webp",
    name: "Legacy of the Wild",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-072",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-073.webp",
    name: "Archer",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-073",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-001.webp",
    name: "Triple Monkey",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-001",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-003.webp",
    name: "Buffalo Blitz",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-003",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-006.webp",
    name: "Great Blue",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-006",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-004.webp",
    name: "Captain Treasure",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-004",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-005.webp",
    name: "Long Long Long",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-005",
    cateogry: "PT",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/PT-SLOT-009.webp",
    name: "Golden Tour",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-009",
    cateogry: "PT",
    type: "SLOT",
  },
  
  {
    img: "../assets/images/slot/PT-SLOT-010.webp",
    name: "Funky Monkey",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-010",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-011.webp",
    name: "Highway Kings",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-011",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-014.webp",
    name: "Zhao Cai Tong Zi",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-014",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-012.webp",
    name: "Zhao Cai Jin Bao",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-012",
    cateogry: "PT",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/PT-SLOT-013.webp",
    name: "Jin Qian Wa",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-013",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-015.webp",
    name: "Ugga Bugga",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-015",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-016.webp",
    name: "Safari Heat",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-016",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-002.webp",
    name: "Arowanas Luck",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-002",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-019.webp",
    name: "Gem Queen",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-019",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-023.webp",
    name: "Chaoji 888",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-023",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-008.webp",
    name: "Blue Wizard",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-008",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-018.webp",
    name: "Bonus Bears",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-018",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-017.webp",
    name: "Buffalo Blitz II",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-017",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-007.webp",
    name: "Sky Queen",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-007",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-020.webp",
    name: "Feng Kuang Ma Jiang",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-020",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-021.webp",
    name: "Epic Ape",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-021",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-022.webp",
    name: "Ni Shu Shen Me",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-022",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-024.webp",
    name: "Vacation Station Deluxe",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-024",
    cateogry: "PT",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/PT-SLOT-025.webp",
    name: "Eternal Lady",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-025",
    cateogry: "PT",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/PT-SLOT-026.webp",
    name: "Golden Macaque",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-026",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-027.webp",
    name: "Fei Long Zai Tian",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-027",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-028.webp",
    name: "Yun Cong Long",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-028",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-029.webp",
    name: "Jinfu Long",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-029",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-030.webp",
    name: "Hot Gems",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-030",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-031.webp",
    name: "Lie Yan Zuan Shi",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-031",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-032.webp",
    name: "Dolphin Reef",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-032",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-033.webp",
    name: "Jinns Moon",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-033",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-034.webp",
    name: "Tiger, Turtle, Dragon, Phoenix",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-034",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-035.webp",
    name: "Heart of the Jungle",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-035",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-036.webp",
    name: "Heavenly Ruler",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-036",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-037.webp",
    name: "Ox Riches",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-037",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-038.webp",
    name: "Ice Cave",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-038",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-039.webp",
    name: "Queen of Wands",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-039",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-040.webp",
    name: "Si Xiang",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-040",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-041.webp",
    name: "Panther Moon",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-041",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-043.webp",
    name: "Asian Fantasy",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-043",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-044.webp",
    name: "Fortune Lions",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-044",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-045.webp",
    name: "Hot KTV",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-045",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-046.webp",
    name: "Fortune Day",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-046",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-047.webp",
    name: "Ri Ri Jin Cai",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-047",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-048.webp",
    name: "Sun WuKong",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-048",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-049.webp",
    name: "Ji Xiang 8",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-049",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-050.webp",
    name: "Jade Emperor Yu Huang Da Di",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-050",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-051.webp",
    name: "Halloween Fortune 2",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-051",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-060.webp",
    name: "Chinese Kitchen",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-060",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-061.webp",
    name: "Geisha Story",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-061",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-062.webp",
    name: "Fortunes of the Fox",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-062",
    cateogry: "PT",
    type: "SLOT",
  },
  // Slot End PT (casino Done) -----

  // Slot Start JDB (casino Done) -----
  {
    img: "../assets/images/slot/JDB-SLOT-108.webp",
    name: "Moneybags Man 2",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-108",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-106.webp",
    name: "FortuneNeko",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-106",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-107.webp",
    name: "Trump Card",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-107",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-105.webp",
    name: "BookOfMystery",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-105",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-001.webp",
    name: "Burglar",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-001",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-003.webp",
    name: "Chef Doeuvre",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-003",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-004.webp",
    name: "Lucky Miner",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-004",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-005.webp",
    name: "Candy Land",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-005",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-006.webp",
    name: "Crazy Scientist",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-006",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-007.webp",
    name: "Super Dumpling",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-007",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-008.webp",
    name: "Cash Man",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-008",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-009.webp",
    name: "Wild Beauty",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-009",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-010.webp",
    name: "Flirting Scholar Tang",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-010",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-011.webp",
    name: "Winning Mask",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-011",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-012.webp",
    name: "Wu kong",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-012",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-013.webp",
    name: "Llama Adventure",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-013",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-014.webp",
    name: "Formosa Bear",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-014",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-015.webp",
    name: "Lucky Qilin",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-015",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-016.webp",
    name: "New Year",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-016",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-017.webp",
    name: "Four Treasures",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-017",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-018.webp",
    name: "Open Sesame",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-018",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-019.webp",
    name: "Banana Saga",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-019",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-020.webp",
    name: "Olympian Temple",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-020",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-021.webp",
    name: "Dancing Pa Pa",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-021",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-022.webp",
    name: "Dragon King",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-022",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-023.webp",
    name: "Magic Show",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-023",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-024.webp",
    name: "Cock Fight",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-024",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-025.webp",
    name: "Dragon",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-025",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-026.webp",
    name: "Rooster In Love",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-026",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-027.webp",
    name: "Monkey King",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-027",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-028.webp",
    name: "Moonlight Treasure",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-028",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-029.webp",
    name: "Mahjong",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-029",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-030.webp",
    name: "Guan Gong",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-030",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-031.webp",
    name: "Billionaire",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-031",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-032.webp",
    name: "Dragon Warrior",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-032",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-033.webp",
    name: "Kingsman",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-033",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-034.webp",
    name: "Curvy Magician",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-034",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-035.webp",
    name: "RollingIn Money",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-035",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-036.webp",
    name: "Chef Panda",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-036",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-045.webp",
    name: "Lucky Dragons",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-045",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-046.webp",
    name: "Lucky Lion",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-046",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-047.webp",
    name: "Coffeeholics",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-047",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-048.webp",
    name: "Napoleon",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-048",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-049.webp",
    name: "Lucky Phoenix",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-049",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-050.webp",
    name: "WinningMask II",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-050",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-051.webp",
    name: "Open Sesame II",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-051",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-052.webp",
    name: "Flirting Scholar Tang II",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-052",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-053.webp",
    name: "Fortune Horse",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-053",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-054.webp",
    name: "Xi Yang Yang",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-054",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-055.webp",
    name: "Panda Panda",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-055",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-056.webp",
    name: "Zelda",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-056",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-057.webp",
    name: "Mr Bao",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-057",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-058.webp",
    name: "One Punch Man",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-058",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-059.webp",
    name: "Guardians of The Galaxy",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-059",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-060.webp",
    name: "Street Fighter",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-060",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-061.webp",
    name: "Star Wars",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-061",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-062.webp",
    name: "War of Beauty",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-062",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-063.webp",
    name: "Daji",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-063",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-064.webp",
    name: "Poker King",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-064",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-065.webp",
    name: "Lucky Racing",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-065",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-066.webp",
    name: "Birds Party",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-066",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-067.webp",
    name: "Go Lai Fu",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-067",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-068.webp",
    name: "Gems Gems",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-068",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-069.webp",
    name: "Dragons World",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-069",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-070.webp",
    name: "Super Niubi",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-070",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-071.webp",
    name: "Egypt Treasure",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-071",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-072.webp",
    name: "Fortune Treasure",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-072",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-073.webp",
    name: "Pirate Treasure",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-073",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-074.webp",
    name: "Mjoinir",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-074",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-075.webp",
    name: "Treasure Bowl",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-075",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-076.webp",
    name: "Funky King Kong",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-076",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-077.webp",
    name: "Super Niubi Deluxe",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-077",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-078.webp",
    name: "Super Niubi",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-078",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-079.webp",
    name: "Lucky Fuwa",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-079",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-080.webp",
    name: "Inca Empire",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-080",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-081.webp",
    name: "Ninja Rush",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-081",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-082.webp",
    name: "Sun Archer",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-082",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-083.webp",
    name: "Mystery of Nine Tales",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-083",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-084.webp",
    name: "Golden Disco",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-084",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-085.webp",
    name: "Miner Babe",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-085",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-086.webp",
    name: "Moneybags Man",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-086",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-087.webp",
    name: "Double Wilds",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-087",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-088.webp",
    name: "Pop Pop Fruity",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-088",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-089.webp",
    name: "Spindrift",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-089",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-090.webp",
    name: "Spindrift 2",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-090",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-091.webp",
    name: "Jungle Jungle",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-091",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-092.webp",
    name: "Dragons Gate",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-092",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-093.webp",
    name: "Lucky Diamond",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-093",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-094.webp",
    name: "Kong",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-094",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-095.webp",
    name: "NinjaX",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-095",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-096.webp",
    name: "Rex Brothers",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-096",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-097.webp",
    name: "Wonder Elephant",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-097",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-098.webp",
    name: "MarvelousIV",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-098",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-099.webp",
    name: "Maya Gold Crazy",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-099",
    cateogry: "JDB",
    type: "SLOT",
  },
  // Slot End JDB (casino Done) -----

  // Slot Start FC (casino Done) -----

  // {
  //   img: "../assets/images/slot/FC-SLOT-025.webp",
  //   name: "SUGAR BANG BANG",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-025",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-021.webp",
  //   name: "Lucky Fortunes",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-021",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-020.webp",
  //   name: "Robin Hood",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-020",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-019.webp",
  //   name: "GLORY OF ROME",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-019",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-001.webp",
  //   name: "GOLDEN PANTHER",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-001",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-002.webp",
  //   name: "THREE LITTLE PIGS",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-002",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-003.webp",
  //   name: "HOT POT PARTY",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-003",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-004.webp",
  //   name: "NIGHT MARKET",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-004",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-005.webp",
  //   name: "PONG PONG HU",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-005",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-006.webp",
  //   name: "PANDA DRAGON BOAT",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-006",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-007.webp",
  //   name: "CHINESE NEW YEAR",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-007",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-008.webp",
  //   name: "FORTUNE KOI",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-008",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-009.webp",
  //   name: "HAPPY DUO BAO",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-009",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-010.webp",
  //   name: "	DA LE MEN",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-010",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-011.webp",
  //   name: "ANIMAL RACING",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-011",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-012.webp",
  //   name: "LUXURY GOLDEN PANTHER",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-012",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-013.webp",
  //   name: "MAGIC BEANS",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-013",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-014.webp",
  //   name: "COWBOYS",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-014",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-015.webp",
  //   name: "WAR OF THE UNIVERSE",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-015",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-016.webp",
  //   name: "TREASURE CRUISE",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-016",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // Slot End FC (casino Done) -----

  // Slot Start JILI (casino Done) -----
   {
    img: "../assets/images/slot/JILI-SLOT-063.webp",
    name: "World Cup",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-063",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-056.webp",
    name: "Happy Taxi",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-056",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-057.webp",
    name: "Gold Rush",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-057",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-054.webp",
    name: "Crazy Pusher",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-054",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-053.webp",
    name: "Book of Gold",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-053",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-071.webp",
    name: "Neko Fortune",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-071",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-051.webp",
    name: "Mega Ace",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-051",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-052.webp",
    name: "Thor X",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-052",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-002.webp",
    name: "Hot Chilli",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-002",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-003.webp",
    name: "Chin Shi Huang",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-003",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-004.webp",
    name: "War Of Dragons",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-004",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-005.webp",
    name: "Fortune Tree",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-005",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-006.webp",
    name: "Lucky Ball",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-006",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-007.webp",
    name: "Hyper Burst",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-007",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-008.webp",
    name: "Shanghai Beauty",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-008",
    cateogry: "JILI",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/JILI-SLOT-009.webp",
    name: "Fa Fa Fa",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-009",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-010.webp",
    name: "God Of Martial",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-010",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-012.webp",
    name: "Hawaii Beauty",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-012",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-013.webp",
    name: "SevenSevenSeven",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-013",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-014.webp",
    name: "Crazy777",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-014",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-015.webp",
    name: "Bubble Beauty",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-015",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-016.webp",
    name: "Bao boon chin",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-016",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-017.webp",
    name: "Crazy FaFaFa",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-017",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-018.webp",
    name: "XiYangYang",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-018",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-019.webp",
    name: "FortunePig",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-019",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-020.webp",
    name: "Candy Baby",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-020",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-021.webp",
    name: "Diamond Party",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-021",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-022.webp",
    name: "Fengshen",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-022",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-023.webp",
    name: "Golden Bank",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-023",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-024.webp",
    name: "Lucky Goldbricks",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-024",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-025.webp",
    name: "Dragon Treasure",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-025",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-026.webp",
    name: "Charge Buffalo",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-026",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-027.webp",
    name: "Super Ace",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-027",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-028.webp",
    name: "Jungle King",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-028",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-029.webp",
    name: "Money Coming",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-029",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-030.webp",
    name: "Golden Queen",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-030",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-031.webp",
    name: "Boxing King",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-031",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-032.webp",
    name: "Matka India",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-032",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-037.webp",
    name: "Lucky Coming",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-037",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-038.webp",
    name: "Pharaoh Treasure",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-038",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-039.webp",
    name: "Secret Treasure",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-039",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-040.webp",
    name: "RomaX",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-040",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-041.webp",
    name: "Super Rich",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-041",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-042.webp",
    name: "Golden Empire",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-042",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-043.webp",
    name: "Fortune Gems",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-043",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-044.webp",
    name: "Party Night",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-044",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-045.webp",
    name: "Crazy Hunter",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-045",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-046.webp",
    name: "Magic Lamp",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-046",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-047.webp",
    name: "TWINWINS",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-047",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-048.webp",
    name: "Agent Ace",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-048",
    cateogry: "JILI",
    type: "SLOT",
  },
  // Slot End JILI (casino Done) -----

  //slot spade start
  {
    img: "../assets/images/slot/SG-SLOT-106.png",
    name: "Fiery Sevens Exclusive",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-106",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-074.png",
    name: "Mega7",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-074",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-116.png",
    name: "Legacy Of Kong Maxways",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-116",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-069.webp",
    name: "888",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-069",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-112.webp",
    name: "Fruits Mania",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-112",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-076.png",
    name: "DoubleFlame",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-076",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-110.webp",
    name: "Space Conquest",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-110",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-081.png",
    name: "TriplePanda",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-081",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-077.png",
    name: "BookofMyth",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-077",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-095.png",
    name: "Poker Ways",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-095",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-086.png",
    name: "Mayan Gems",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-086",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-075.png",
    name: "JokersTreasure",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-075",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-030.png",
    name: "5FortuneSA",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-030",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-103.webp",
    name: "Sexy Vegas",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-103",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-107.png",
    name: "Lucky Koi Exclusive",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-107",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-082.png",
    name: "GoldPanther",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-082",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-032.webp",
    name: "GoldenLotusSE",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-032",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-105.png",
    name: "Royale House",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-105",
    cateogry: "SPADE",
    type: "SLOT",
  },

  //slot spade end
  {
    img: "../assets/images/slot/FC-SLOT-025.png",
    name: "SUGAR BANG BANG",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-025",
    cateogry: "FC",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/FC-SLOT-023.png",
    name: "GRAND BLUE",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-023",
    cateogry: "FC",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/FC-SLOT-024.png",
    name: "TREASURE RAIDERS",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-024",
    cateogry: "FC",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/FC-SLOT-021.png",
    name: "Lucky Fortunes",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-021",
    cateogry: "FC",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/FC-SLOT-020.png",
    name: "Robin Hood",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-020",
    cateogry: "FC",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/FC-SLOT-019.png",
    name: "GLORY OF ROME",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-019",
    cateogry: "FC",
    type: "SLOT",
  },{
    img: "../assets/images/slot/FC-SLOT-001.png",
    name: "GOLDEN PANTHER",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-001",
    cateogry: "FC",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/FC-SLOT-002.png",
    name: "THREE LITTLE PIGS",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-002",
    cateogry: "FC",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/FC-SLOT-003.png",
    name: "HOT POT PARTY",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-003",
    cateogry: "FC",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/FC-SLOT-004.png",
    name: "NIGHT MARKET",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-004",
    cateogry: "FC",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/FC-SLOT-005.png",
    name: "PONG PONG HU",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-005",
    cateogry: "FC",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/FC-SLOT-006.png",
    name: "PANDA DRAGON BOAT",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-006",
    cateogry: "FC",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/FC-SLOT-007.png",
    name: "CHINESE NEW YEAR",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-007",
    cateogry: "FC",
    type: "SLOT",
  },


  {
    img: "../assets/images/slot/FC-SLOT-008.png",
    name: "FORTUNE KOI",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-008",
    cateogry: "FC",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/FC-SLOT-009.png",
    name: "HAPPY DUO BAO",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-009",
    cateogry: "FC",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/FC-SLOT-010.png",
    name: "DA LE MEN",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-010",
    cateogry: "FC",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/FC-SLOT-011.png",
    name: "ANIMAL RACING",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-011",
    cateogry: "FC",
    type: "SLOT",
  },


  {
    img: "../assets/images/slot/FC-SLOT-012.png",
    name: "LUXURY GOLDEN PANTHER",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-012",
    cateogry: "FC",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/FC-SLOT-013.png",
    name: "MAGIC BEANS",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-013",
    cateogry: "FC",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/FC-SLOT-014.png",
    name: "COWBOYS",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-014",
    cateogry: "FC",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/FC-SLOT-015.png",
    name: "WAR OF THE UNIVERSE",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-015",
    cateogry: "FC",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/FC-SLOT-016.png",
    name: "TREASURE CRUISE",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-016",
    cateogry: "FC",
    type: "SLOT",
  },



  //slot FC start

  //slot FC end

  // Fishing Start JILI (casino Done) -----
  {
    img: "../assets/images/Fishing/JDB-FISH-008.webp",
    name: "Royal Fishing",
    platForm: "FC",
    gameType: "FH",
    casinoType: "FC-FISH-001",
    cateogry: "JDB",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/JDB-FISH-010.webp",
    name: "Fishing YiLuFa",
    platForm: "FC",
    gameType: "FH",
    casinoType: "FC-FISH-002",
    cateogry: "JDB",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/JDB-FISH-011.webp",
    name: "Dragon Master",
    platForm: "FC",
    gameType: "FH",
    casinoType: "FC-FISH-004",
    cateogry: "JDB",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/JDB-FISH-013.webp",
    name: "Fishing Legend",
    platForm: "FC",
    gameType: "FH",
    casinoType: "FC-FISH-005",
    cateogry: "JDB",
    type: "FISHING",
  },

// Fishing Start SPADE (casino Done) -----
{
  img: "../assets/images/Fishing/SG-FISH-001.webp",
  name:"Fishing God",
  platForm: "SPADE",
  gameType: "FH",
  casinoType: "SG-FISH-001",
  cateogry: "SPADE",
  type: "FISHING",
},
{
  img: "../assets/images/Fishing/SG-FISH-002.webp",
  name:"Fishing War",
  platForm: "SPADE",
  gameType: "FH",
  casinoType: "SG-FISH-002",
  cateogry: "SPADE",
  type: "FISHING",
},
{
  img: "../assets/images/Fishing/SG-FISH-003.webp",
  name: "Alien Hunter",
  platForm: "SPADE",
  gameType: "FH",
  casinoType: "SG-FISH-003",
  cateogry: "SPADE",
  type: "FISHING",
},
{
  img: "../assets/images/Fishing/SG-FISH-004.webp",
  name: "Zombie Party",
  platForm: "SPADE",
  gameType: "FH",
  casinoType: "SG-FISH-004",
  cateogry: "SPADE",
  type: "FISHING",
},
// Fishing End SPADE (casino Done) -----
{
  img: "../assets/images/Fishing/JILI-FISH-008.webp",
  name: "Boom Legend",
  platForm: "JILI",
  gameType: "FH",
  casinoType: "JILI-FISH-008",
  cateogry: "JILI",
  type: "FISHING",
},
  {
    img: "../assets/images/Fishing/JILI-FISH-001.webp",
    name: "Royal Fishing",
    platForm: "JILI",
    gameType: "FH",
    casinoType: "JILI-FISH-001",
    cateogry: "JILI",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/JILI-FISH-004.webp",
    name: "Dinosaur Tycoon",
    platForm: "JILI",
    gameType: "FH",
    casinoType: "JILI-FISH-004",
    cateogry: "JILI",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/JILI-FISH-002.webp",
    name: "Bombing Fishing",
    platForm: "JILI",
    gameType: "FH",
    casinoType: "JILI-FISH-002",
    cateogry: "JILI",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/JILI-FISH-007.webp",
    name: "Mega Fishing",
    platForm: "JILI",
    gameType: "FH",
    casinoType: "JILI-FISH-007",
    cateogry: "JILI",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/JILI-FISH-006.webp",
    name: "Dragon Fortune",
    platForm: "JILI",
    gameType: "FH",
    casinoType: "JILI-FISH-006",
    cateogry: "JILI",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/JILI-FISH-005.webp",
    name: "Happy Fishing",
    platForm: "JILI",
    gameType: "FH",
    casinoType: "JILI-FISH-005",
    cateogry: "JILI",
    type: "FISHING",
  },
 
  {
    img: "../assets/images/Fishing/JILI-FISH-003.webp",
    name: "Jackpot Fishing",
    platForm: "JILI",
    gameType: "FH",
    casinoType: "JILI-FISH-003",
    cateogry: "JILI",
    type: "FISHING",
  },
 
  
  
 
  // Fishing End JILI (casino Done) -----
// EGAME SPRIBE
{
  img: "../assets/images/Egame/22001.png",
  name: "Aviator",
  platForm: "SPRIBE",
  gameType: "EGAME",
  casinoType: "SPRIBE-EGAME-001",
  cateogry: "SPRIBE",
  type: "EGAME",
},
{
  img: "../assets/images/Egame/22002.png",
  name:"Dice",
  platForm: "SPRIBE",
  gameType: "EGAME",
  casinoType: "SPRIBE-EGAME-003",
  cateogry: "SPRIBE",
  type: "EGAME",
},
{
  img: "../assets/images/Egame/22008.png",
  name:"Mini Roulette",
  platForm: "SPRIBE",
  gameType: "EGAME",
  casinoType: "SPRIBE-EGAME-008",
  cateogry: "SPRIBE",
  type: "EGAME",
},
{
  img: "../assets/images/Egame/22005.png",
  name:"Mines",
  platForm: "SPRIBE",
  gameType: "EGAME",
  casinoType: "SPRIBE-EGAME-005",
  cateogry: "SPRIBE",
  type: "EGAME",
},
{
  img: "../assets/images/Egame/22006.png",
  name:"Spribe Hilo",
  platForm: "SPRIBE",
  gameType: "EGAME",
  casinoType: "SPRIBE-EGAME-006",
  cateogry: "SPRIBE",
  type: "EGAME",
},
{
  img: "../assets/images/Egame/22004.png",
  name:"Plinko",
  platForm: "SPRIBE",
  gameType: "EGAME",
  casinoType: "SPRIBE-EGAME-004",
  cateogry: "SPRIBE",
  type: "EGAME",
},
{
  img: "../assets/images/Egame/22003.png",
  name:"Goal",
  platForm: "SPRIBE",
  gameType: "EGAME",
  casinoType: "SPRIBE-EGAME-002",
  cateogry: "SPRIBE",
  type: "EGAME",
},
{
  img: "../assets/images/Egame/22009.png",
  name:"Hotline",
  platForm: "SPRIBE",
  gameType: "EGAME",
  casinoType: "SPRIBE-EGAME-009",
  cateogry: "SPRIBE",
  type: "EGAME",
},
{
  img: "../assets/images/Egame/22007.png",
  name:"Keno",
  platForm: "SPRIBE",
  gameType: "EGAME",
  casinoType: "SPRIBE-EGAME-007",
  cateogry: "SPRIBE",
  type: "EGAME",
},

  // Egame Start JDB (casino Done) -----
  {
    img: "../assets/images/Egame/JDB-EGAME-002.webp",
    name:"Lucky Color Game",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-009",
    cateogry: "JDB",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/JDB-EGAME-006.webp",
    name:"King Of Football",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-010",
    cateogry: "JDB",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/JDB-EGAME-001.webp",
    name: "Crazy King Kong",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-011",
    cateogry: "JDB",
    type: "EGAME",
  },

  {
    img: "../assets/images/Egame/JDB-EGAME-007.webp",
    name:"Super Super Fruit",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-012",
    cateogry: "JDB",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/JDB-EGAME-011.webp",
    name:"Jogo Do Bicho",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-013",
    cateogry: "JDB",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/JDB-EGAME-010.webp",
    name:"Beer Tycoon",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-014",
    cateogry: "JDB",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/JDB-EGAME-005.webp",
    name:"CAISHEN PARTY",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-015",
    cateogry: "JDB",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/JDB-EGAME-003.webp",
    name:"Birds and Animals",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-016",
    cateogry: "JDB",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/JDB-EGAME-009.webp",
    name:"Classic Mario",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-017",
    cateogry: "JDB",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/JDB-EGAME-004.webp",
    name:"Happy New Year",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-018",
    cateogry: "JDB",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/JDB-EGAME-008.webp",
    name:"Huaguoshan Legends",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JJDB-SLOT-019",
    cateogry: "JDB",
    type: "EGAME",
  },

    // Egame End JDB (casino Done) -----

   //PP EGAME START

  {
    img: "../assets/images/Egame/PP-EGAME-004.webp",
    name:"Fantastic League",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-001",
    cateogry: "PP",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/PP-EGAME-002.webp",
    name:"Penalty Shootout",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-002",
    cateogry: "PP",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/PP-EGAME-005.webp",
    name:"Greyhound Racing",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-005",
    cateogry: "PP",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/PP-EGAME-003.webp",
    name:"Horse Racing",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-003",
    cateogry: "PP",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/PP-EGAME-006.webp",
    name:"Force 1",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-006",
    cateogry: "PP",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/PP-EGAME-007.webp",
    name:"Darts",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-007",
    cateogry: "PP",
    type: "EGAME",
  },


  //PP EGAME END
  

  {
    img: "../assets/images/game-shows/HOTROAD-LIVE-001.png",
    name: "BACCARAT",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-001",
    cateogry: "HOTROAD",
    type: "GAME-SHOWS",
  },

  {
    img: "../assets/images/game-shows/HOTROAD-LIVE-007.png",
    name: " HOT Baccarat",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-007",
    cateogry: "HOTROAD",
    type: "GAME-SHOWS",
  },

  {
    img: "../assets/images/game-shows/HOTROAD-LIVE-004.png",
    name: "HOT ROULETTE",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-004",
    cateogry: "HOTROAD",
    type: "GAME-SHOWS",
  },

  {
    img: "../assets/images/game-shows/HOTROAD-LIVE-006.png",
    name: "HOT SICBO",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-006",
    cateogry: "HOTROAD",
    type: "GAME-SHOWS",
  },
  // Egame Start PP (casino Done) -----
  
  
  

  // Egame End PP (casino Done) -----
  // crash SPRIBE
  // {
  //   img: "../assets/images/Table/22001.png",
  //   name:"SPRIBE Aviator",
  //   platForm: "SPRIBE",
  //   gameType: "EGAME",
  //   casinoType: "SPRIBE-EGAME-001",
  //   cateogry: "SPRIBE",
  //   type: "CRASH",
  // },
// crash KINGMAKER
{
  img: "../assets/images/Table/KM-TABLE-041.png",
  name:"KM Cash Rocket",
  platForm: "KINGMAKER",
  gameType: "TABLE",
  casinoType: "KM-TABLE-041",
  cateogry: "KINGMAKER",
  type: "CRASH",
},

{
  img: "../assets/images/Table/KM-TABLE-069.png",
  name:"KM Cash Rocket",
  platForm: "KINGMAKER",
  gameType: "TABLE",
  casinoType: "KM-TABLE-069",
  cateogry: "KINGMAKER",
  type: "CRASH",
},
{
  img: "../assets/images/Table/KM-TABLE-070.png",
  name:"KM Cash Rocket",
  platForm: "KINGMAKER",
  gameType: "TABLE",
  casinoType: "KM-TABLE-070",
  cateogry: "KINGMAKER",
  type: "CRASH",
},
// crash JILI
{
  img: "../assets/images/Table/JILI-TABLE-047.png",
  name:"Crash Bonus",
  platForm: "JILI",
  gameType: "TABLE",
  casinoType: "JILI-TABLE-047",
  cateogry: "JILI",
  type: "CRASH",
},
{
  img: "../assets/images/Table/JILI-TABLE-030.png",
  name:"Go Rush",
  platForm: "JILI",
  gameType: "TABLE",
  casinoType: "JILI-TABLE-030",
  cateogry: "JILI",
  type: "CRASH",
},
{
  img: "../assets/images/Table/JILI-TABLE-035.png",
  name:"Limbo",
  platForm: "JILI",
  gameType: "TABLE",
  casinoType: "JILI-TABLE-035",
  cateogry: "JILI",
  type: "CRASH",
},
// crash PP
{
  img: "../assets/images/Table/PP-EGAME-001.png",
  name:"PP Spaceman",
  platForm: "PP",
  gameType: "EGAME",
  casinoType: "PP-EGAME-001",
  cateogry: "PP",
  type: "CRASH",
},
{
  img: "../assets/images/Table/PP-EGAME-009.png",
  name:"PP Big Bass Crash",
  platForm: "PP",
  gameType: "EGAME",
  casinoType: "PP-EGAME-009",
  cateogry: "PP",
  type: "CRASH",
},
 // EGAME SPRIBE
 {
  img: "../assets/images/Egame/22001.png",
  name: "Aviator",
  platForm: "SPRIBE",
  gameType: "EGAME",
  casinoType: "SPRIBE-EGAME-001",
  cateogry: "SPRIBE",
  type: "EGAME",
},
{
  img: "../assets/images/Egame/22002.png",
  name: "Dice",
  platForm: "SPRIBE",
  gameType: "EGAME",
  casinoType: "SPRIBE-EGAME-003",
  cateogry: "SPRIBE",
  type: "EGAME",
},
{
  img: "../assets/images/Egame/22008.png",
  name: "Mini Roulette",
  platForm: "SPRIBE",
  gameType: "EGAME",
  casinoType: "SPRIBE-EGAME-008",
  cateogry: "SPRIBE",
  type: "EGAME",
},
{
  img: "../assets/images/Egame/22005.png",
  name: "Mines",
  platForm: "SPRIBE",
  gameType: "EGAME",
  casinoType: "SPRIBE-EGAME-005",
  cateogry: "SPRIBE",
  type: "EGAME",
},
{
  img: "../assets/images/Egame/22006.png",
  name: "Spribe Hilo",
  platForm: "SPRIBE",
  gameType: "EGAME",
  casinoType: "SPRIBE-EGAME-006",
  cateogry: "SPRIBE",
  type: "EGAME",
},
{
  img: "../assets/images/Egame/22004.png",
  name: "Plinko",
  platForm: "SPRIBE",
  gameType: "EGAME",
  casinoType: "SPRIBE-EGAME-004",
  cateogry: "SPRIBE",
  type: "EGAME",
},


];


export const bannerSlider = [
  { id: 1, img: banner1 },
  { id: 2, img: banner2 },
  {
    id: 3,
    img: banner3,
  },
];

export const showMenuUsingPath = [{ path: "/change-password" }, { path: "" }];
